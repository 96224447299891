import React, { ReactNode, useRef, useState } from "react";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { DataTableMultiselect } from "@/components/display/DataTableMultiselect";
import { t } from "i18next";
import { InvoiceDraft, InvoiceRequest } from "@/models/invoice";
import { DataTableSortSelector } from "@/components/display/DataTableSortSelector";
import { DataTableNoEntriesHandler } from "@/components/display/DataTableNoEntries";
import Spinner from "@/components/loading/spinner";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import { FilterInner, FilterParameters, FilterResult } from "@/api/types";
import {
  DataTablePagination,
  DataTablePaginationElement,
} from "@/components/display/DataTablePagination";
import {
  DataTable,
  DataTableColumn,
  Sort,
} from "@/components/display/DataTable";
import { Path } from "@/util/DeepProps";
import { formatDateTime } from "@/util/format";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Link } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import InvoiceActions from "@/feature/invoices/read/_table/InvoiceActions";
import { Button } from "@/components/ui/button";
import { InvoiceLayout } from "@/feature/invoices/edit/form/InvoiceLayout";
import { LOADING_TEMPLATE } from "@/feature/invoices/edit/form/loadingTemplate";

type SetSortType = (
  value:
    | ((
        prevState: [keyof InvoiceDraft, "asc" | "desc"] | null,
      ) => [keyof InvoiceDraft, "asc" | "desc"] | null)
    | [keyof InvoiceDraft, "asc" | "desc"]
    | null,
) => void;

type SetFilterType = (
  value:
    | ((prevState: FilterInner<InvoiceDraft>) => FilterInner<InvoiceDraft>)
    | FilterInner<InvoiceDraft>,
) => void;

interface FiltersSectionProps {
  setFilter: SetFilterType;
  setSort: SetSortType;
}

interface ContentSectionProps {
  data: FilterResult<InvoiceDraft> | undefined;
  isLoading: boolean;
  onSortChange: SetSortType;
  columns: DataTableColumn<InvoiceDraft>[];
  onItemClick: (item: InvoiceDraft) => void;
  paginationRef: React.RefObject<DataTablePaginationElement<InvoiceDraft>>;
  setFilterParams: (params: FilterParameters<InvoiceDraft>) => void;
  selectedInvoiceId?: string | undefined;
}

const InvoiceTable = () => {
  const [sort, setSort] = useState<Sort<Path<InvoiceDraft>>>(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<
    string | undefined
  >();
  const [filter, setFilter] = useState<FilterInner<InvoiceDraft>>({});
  const [filterParams, setFilterParams] =
    useState<FilterParameters<InvoiceDraft>>();
  const paginationRef = useRef<DataTablePaginationElement<InvoiceDraft>>(null);
  const [preview, setPreview] = useState<string>(LOADING_TEMPLATE);
  const [triggerPreview] = invoiceApi.useLazyPreviewQuery();
  const { data, isLoading } = invoiceApi.useFilterQuery(
    {
      sort: sort ? sort[0] : "updatedAt",
      direction: sort ? sort[1] : "desc",
      ...filterParams,
      ...filter,
    },
    { skip: !filter || !filterParams },
  );

  const columns: DataTableColumn<InvoiceDraft>[] = [
    {
      header: t("component.invoices.table.invoiceNumber"),
      cell: (item) => (
        <InvoiceNumberCell
          item={item}
          setSelectedInvoiceId={setSelectedInvoiceId}
          selectedInvoiceId={selectedInvoiceId}
          setPreview={setPreview}
        />
      ),
    },
    {
      header: t("component.invoices.table.type"),
      cell: (item) => t(`component.invoices.table.typeCode.${item.typeCode}`),
    },
    {
      header: t("component.invoices.table.status.label"),
      cell: (item) => t(`component.invoices.table.status.${item.status}`),
    },

    {
      header: t("component.invoices.table.lastEdited"),
      cell: (item) => formatDateTime(new Date(item.updatedAt)),
    },
    {
      cell: (item) => (
        <InvoiceActions
          invoice={item}
          setSelectedInvoiceId={setSelectedInvoiceId}
          selectedInvoiceId={selectedInvoiceId}
        />
      ),
    },
  ];

  return (
    <VerticalFlex>
      <FiltersSection setFilter={setFilter} setSort={setSort} />
      <InvoiceLayout
        preview={preview}
        showPreview={selectedInvoiceId !== undefined}
      >
        <ContentSection
          data={data}
          isLoading={isLoading}
          onSortChange={setSort}
          columns={columns}
          onItemClick={() => {
            //TODO maybe later do something here
          }}
          paginationRef={paginationRef}
          setFilterParams={setFilterParams}
          selectedInvoiceId={selectedInvoiceId}
        />
      </InvoiceLayout>
    </VerticalFlex>
  );
};

const FiltersSection = ({ setFilter, setSort }: FiltersSectionProps) => (
  <HorizontalFlex>
    <DataTableMultiselect<InvoiceDraft>
      selectGroups={[]}
      placeholder={t("component.invoices.table.search")}
      textSearchKeys={["invoiceNumber", "draftRequest", "typeCode"]}
      onFilterChange={setFilter}
    />
    <div className="flex flex-1">
      <DataTableSortSelector<InvoiceDraft>
        options={[
          {
            label: t("component.invoices.table.lastEdited"),
            value: "updatedAt",
          },
          { label: t("model.document.invoiceNumber"), value: "invoiceNumber" },
        ]}
        onChange={setSort}
        default={["updatedAt", "desc"]}
      />
    </div>
  </HorizontalFlex>
);

const ContentSection = ({
  data,
  isLoading,
  onSortChange,
  columns,
  onItemClick,
  paginationRef,
  setFilterParams,
  selectedInvoiceId,
}: ContentSectionProps) => (
  <VerticalFlex
    gap={8}
    className="flex-auto text-muted-foreground transition-all duration-300"
  >
    <DataTableNoEntriesHandler result={data} />
    {isLoading && <Spinner />}
    {!isLoading && data && data.items.length !== 0 && (
      <DataTable<InvoiceDraft>
        data={data.items}
        onSortChange={onSortChange}
        columns={columns}
        selected={[selectedInvoiceId as string]}
        onClick={(item) => onItemClick(item)}
      />
    )}
    <DataTablePagination
      ref={paginationRef}
      defaultPageSize={25}
      result={data}
      onChange={setFilterParams}
    />
  </VerticalFlex>
);

const InvoiceNumberCell = ({
  item,
  selectedInvoiceId,
  setSelectedInvoiceId,
  setPreview,
}: {
  item: InvoiceDraft;
  setSelectedInvoiceId: (
    value:
      | ((prevState: string | undefined) => string | undefined)
      | string
      | undefined,
  ) => void;
  selectedInvoiceId: string | undefined;
  setPreview: (value: ((prevState: string) => string) | string) => void;
}) => {
  const [triggerPreview] = invoiceApi.useLazyPreviewQuery();

  const displayNode: ReactNode = (
    <HorizontalFlex className="transition-all">
      <span className={"my-auto text-foreground"}>{item.invoiceNumber}</span>{" "}
      <Button
        variant={"ghost"}
        size={"icon"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          {
            setSelectedInvoiceId((prev) =>
              prev === item.id ? undefined : item.id,
            );
            triggerPreview({
              id: item.id,
              body: item.draftRequest as InvoiceRequest,
            }).then((response) => {
              if (response.data) {
                setPreview(response.data);
              }
            });
          }
        }}
        type={"button"}
      >
        {selectedInvoiceId !== item.id ? (
          <Eye size={18} className={"text-muted-foreground"} />
        ) : (
          <EyeOff size={18} className={"text-muted-foreground"} />
        )}
      </Button>
    </HorizontalFlex>
  );
  // TODO i18n &  Verlinkung auf das Dokument, sobald möglich
  if (item.status === "DRAFT") {
    return displayNode;
  } else {
    return (
      <HoverCard>
        <HoverCardTrigger>{displayNode}</HoverCardTrigger>
        <HoverCardContent>
          <Link to="/documents" className="underline">
            Mehr Infos zu {item.invoiceNumber}
          </Link>
        </HoverCardContent>
      </HoverCard>
    );
  }
};
export default InvoiceTable;
