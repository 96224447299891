import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import { ClockLoader } from "react-spinners";
import { Center } from "@/components/layout/Center";
import { t } from "i18next";

const FinalizeLoadingScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [polling, setPolling] = useState(true);

  const { data } = invoiceApi.useGetInvoiceQuery(id as string, {
    skip: !polling,
    pollingInterval: 1000,
  });

  useEffect(() => {
    if (data && data.documentId !== "00000000-0000-0000-0000-000000000000") {
      setPolling(false);
      navigate("/documents");
    }
  }, [data, navigate]);

  return (
    <Center className={"items-center"}>
      {" "}
      <div className={"mx-auto flex flex-col gap-16"}>
        <ClockLoader
          color="#bdbdbd"
          className={"card-shine-effect mx-auto animate-gradientPulseGlow"}
          size={150}
        />
        <span className={"pt-4"}>{t("component.invoices.finalize")}</span>
      </div>
    </Center>
  );
};

export default FinalizeLoadingScreen;
