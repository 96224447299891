import React from "react";
import { EditCompanyForm } from "@/feature/settings/UserSettings/form/CompanyForm";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { Title } from "@/components/text/Title";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { t } from "i18next";

const CompanySettingsContent = () => {
  const { activeCompany } = useLoggedIn();
  return (
    <HorizontalFlex>
      <VerticalFlex className={"max-w-screen-lg flex-1"}>
        <Title>
          {t("component.companyForm.title", {
            companyName: activeCompany.name,
          })}
        </Title>
        <HorizontalGradientLine />
        <EditCompanyForm />
      </VerticalFlex>
    </HorizontalFlex>
  );
};

export default CompanySettingsContent;
