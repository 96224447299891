import React from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { FormField } from "@/feature/invoices/edit/form/components/FormField";
import { GenericComboBox } from "@/components/form/GenericComboBox";
import { InvoiceRequest, itemSchema, unitCodes } from "@/models/invoice";
import { Button } from "@/components/ui/button";
import { GripVertical, PackagePlus, Trash } from "lucide-react";
import {
  FieldArray,
  FieldArrayMethodProps,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormReturn,
} from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { Textarea } from "@/components/ui/textarea";
import {
  FormControl,
  FormField as RHFFormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { z } from "zod";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { t } from "i18next";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { formatNumberFancy, parseNumber } from "@/util/format";

const LineItem = ({
  form,
  item,
  index,
  defaultValues,
  control,
  remove,
  append,
  disableZeroPercent,
}: {
  form: UseFormReturn<InvoiceRequest>;
  item: FieldArrayWithId<InvoiceRequest>;
  index: number;
  defaultValues: z.infer<typeof itemSchema>;
  control: Control<InvoiceRequest>;
  remove: UseFieldArrayRemove;
  disableZeroPercent: boolean;
  append: (
    value:
      | FieldArray<InvoiceRequest, "items">
      | FieldArray<InvoiceRequest, "items">[],
    options?: FieldArrayMethodProps,
  ) => void;
}) => {
  return (
    <div
      key={item.id}
      className={cn(
        "grid grid-cols-[min-content,repeat(15,minmax(0,1fr))] gap-2 rounded-md p-2 pl-4 shadow transition-all group-hover:bg-accent-foreground/10",
        index % 2 === 0 ? "bg-accent-foreground/5" : "bg-transparent",
      )}
    >
      <VerticalFlex className={"col-span-1 row-span-3 m-auto"}>
        <span className={"block w-3 group-hover:hidden"}>{item.position}</span>
        <GripVertical className={"hidden group-hover:block"} size={12} />
      </VerticalFlex>

      <div className={"col-span-4 grid grid-cols-[1fr_auto] gap-2"}>
        <FormField<InvoiceRequest>
          control={control}
          name={`items.${index}.quantity`}
          type="quantity"
          className={"mb-0 flex-1"}
          defaultValue={defaultValues.quantity}
        />
        <GenericComboBox
          form={form}
          name={`items.${index}.unit`}
          options={unitCodes}
        />
      </div>
      <VerticalFlex className="col-span-10">
        <FormSimpleText
          edit={true}
          itemClassName={"flex-1 "}
          inputClassName={"min-w-32"}
          form={form}
          name={`items.${index}.name`}
          placeholder={"Produktname"}
        />
      </VerticalFlex>

      <VerticalFlex className={"col-span-1 row-span-3 m-auto"}>
        {index === 0 ? (
          <HorizontalFlex className={"mb-2"}>
            <FlexOne />
            <Button
              type="button"
              onClick={() => append(defaultValues)}
              variant={"outline"}
              className={"bg-background/60"}
            >
              <PackagePlus />
            </Button>
          </HorizontalFlex>
        ) : (
          <HorizontalFlex className={"mb-2"}>
            <FlexOne />
            <Button type="button" variant="ghost" onClick={() => remove(index)}>
              <Trash size={16} />
            </Button>
          </HorizontalFlex>
        )}
      </VerticalFlex>

      <VerticalFlex className={`col-span-14 col-start-2 text-sm`}>
        <RHFFormField
          control={control}
          name={`items.${index}.description`}
          render={({ field }) => (
            <FormItem>
              <FormMessage />
              <FormControl>
                <Textarea
                  placeholder="Positionsbeschreibung"
                  {...field}
                  className={"max-h-48"}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </VerticalFlex>
      <VerticalFlex className={"col-span-4"}>
        <FormSimpleNumber
          itemClassName={"flex-1"}
          form={form}
          name={`items.${index}.discount`}
          prefix={() => (
            <span className={"pt-0.5 text-muted-foreground"}>
              {t("common.discountAmount")}
            </span>
          )}
          postfix={() => <span className={"ml-3 pt-0.5"}>{"%"}</span>}
        />
      </VerticalFlex>
      <VerticalFlex className={"col-span-4"}>
        <Select
          value={form.watch(`items.${index}.taxRate`).toString()}
          onValueChange={(value) => {
            form.setValue(`items.${index}.taxRate`, value);
          }}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Steuersatz" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="19">19%</SelectItem>
            <SelectItem value="7">7%</SelectItem>
            <SelectItem value="0">0%</SelectItem>
          </SelectContent>
        </Select>
      </VerticalFlex>
      <VerticalFlex className={"col-span-6"}>
        <FormSimpleNumber
          postfix={(value) => (
            <span
              className={cn("ml-3 pt-0.5", value < 0 ? "text-red-300" : "")}
            >
              {"€"}
            </span>
          )}
          valueDisplay={(value) => formatNumberFancyEuro(value)}
          parseNumber={parseNumberEuro}
          prefix={() => (
            <span className={"pt-0.5 text-muted-foreground"}>
              {form.getValues("priceType") === "net"
                ? t("common.netAmount")
                : t("common.grossAmount")}
            </span>
          )}
          itemClassName={"flex-1"}
          form={form}
          name={`items.${index}.unitPrice`}
          className={"font-semibold"}
        />
      </VerticalFlex>
    </div>
  );
};

export function formatNumberFancyEuro(value: number) {
  return Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
}

export function parseNumberEuro(value: string | number) {
  const strValue = value.toString();
  const v = Math.round(parseFloat(strValue.replace(/,/g, ".")) * 100) / 100;
  if (isNaN(v)) {
    return 0;
  }
  return v;
}

export default LineItem;
