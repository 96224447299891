import React, { FC, useState } from "react";
import { BankAccount } from "@/models/bankAccount";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { formatDate, formatIBAN, formatUpdateAgo } from "@/util/format";
import { useTranslation } from "react-i18next";
import {
  useBankAccountDisableMutation,
  useBankAccountEnableMutation,
  useCreateGoCardlessRequisitionMutation,
  useGetGoCardlessBankInstitutionQuery,
  useGoCardlesRequisitionDeleteMutation,
  useGoCardlesRequisitionSyncMutation,
} from "@/api/endpoints/bankApi";
import { Check, EllipsisVertical, RefreshCw, Trash2 } from "lucide-react";
import { toast } from "@/components/ui/use-toast";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import {
  GoCardlessRequisition,
  GoCardlessRequisitionStatus,
} from "@/models/goCardlessRequisition";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { GoCardlessStatus } from "@/feature/settings/BankSettings/components/GoCardlessStatus";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import Spinner from "@/components/loading/spinner";

export const RequisitionCard: FC<{
  requisition: GoCardlessRequisition;
}> = ({ requisition }) => {
  const { t } = useTranslation();

  const { data: institution } = useGetGoCardlessBankInstitutionQuery({
    id: requisition.goCardlessInstitutionId,
  });

  const finishConnection = () => {
    window.open(requisition.link, "_self");
  };

  const reSyncAuthRequisition = (id: string) => {
    requsitionAuthMutation({
      institutionId: id,
    }).then((res) => {
      if ("data" in res) {
        window.open(res.data.link, "_self");
      }
    });
  };

  const { confirmDelete } = useConfirmModal();

  const onDelete = () => {
    confirmDelete({
      text: t("component.bankSettings.requisitionCard.dropdown.deleteConfirm", {
        institutionName: institution?.name,
      }),
      onAction: async () => {
        let res = await deleteRequisition({ id: requisition.id });
        if ("data" in res) {
          toast({
            icon: Check,
            title: t("common.success"),
            description: t("component.bankSettings.deleteRequisitionSuccess"),
          });
        }
      },
    });
  };

  const [deleteRequisition] = useGoCardlesRequisitionDeleteMutation();

  const [requsitionAuthMutation] = useCreateGoCardlessRequisitionMutation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [syncIsLoading, setSyncIsLoading] = useState(false);

  const [requsitionSyncMutation] = useGoCardlesRequisitionSyncMutation();

  const reSyncRequisition = (id: string) => {
    setSyncIsLoading(true);
    console.log(syncIsLoading);

    requsitionSyncMutation({
      id: id,
    }).then(() => {
      setSyncIsLoading(false);
      console.log(syncIsLoading);
    });
  };

  return (
    <Card
      className={`relative max-w-5xl shadow-black ${isOpen ? "blur-sm" : ""} ${requisition.status === GoCardlessRequisitionStatus.EX ? "border-red-500/50" : "border-none"}`}
    >
      {(requisition.status === GoCardlessRequisitionStatus.EX ||
        requisition.status === GoCardlessRequisitionStatus.LN) && (
        <Button
          className={"absolute right-10 top-3 h-8 text-xs"}
          variant={"outline"}
          onClick={() =>
            reSyncAuthRequisition(requisition.goCardlessInstitutionId)
          }
        >
          <span>
            {t("component.bankSettings.requisitionCard.reSyncRequisition")}
          </span>
        </Button>
      )}
      <VerticalFlex className={"absolute right-2 top-4 text-foreground/50"}>
        <DropdownMenu onOpenChange={toggleDropdown}>
          <DropdownMenuTrigger className={"cursor-pointer"} asChild>
            <EllipsisVertical />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            className="flex w-fit min-w-0 flex-col rounded-md"
          >
            {requisition.status === GoCardlessRequisitionStatus.LN && (
              <DropdownMenuItem
                onClick={() => reSyncRequisition(requisition.id)}
                className="flex cursor-pointer flex-row gap-x-2"
                title={t("common.reSync")}
              >
                <RefreshCw size={18} />
                <span>{t("common.reSync")}</span>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={onDelete}
              className="flex cursor-pointer flex-row gap-x-2 text-red-500"
              title={t("common.delete")}
            >
              <Trash2 size={18} />
              <span>{t("common.delete")}</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </VerticalFlex>
      <CardHeader>
        <CardTitle className={"flex items-center gap-4 text-2xl"}>
          <img
            className={"line-clamp-1 h-8 w-8 items-center rounded-full text-xs"}
            src={institution?.logo}
            alt="Institutionsbild"
          />
          <VerticalFlex gap={0}>
            <HorizontalFlex className={"items-center"} gap={2}>
              {institution?.name || "BankTitel"}
              <GoCardlessStatus requisitionStatus={requisition.status} />
            </HorizontalFlex>
            {requisition.bankAccounts.length > 0 && (
              <span className={"text-sm text-foreground/50"}>
                {t("component.bankSettings.requisitionCard.expirationDate", {
                  date: formatDate(requisition.expiration),
                })}
              </span>
            )}
          </VerticalFlex>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {requisition.status === GoCardlessRequisitionStatus.LN && (
          <VerticalFlex>
            {requisition.bankAccounts.map((b) => {
              return (
                <BankAccountDisplay
                  key={b.id}
                  account={b}
                  lastSync={requisition.lastSync}
                />
              );
            })}
          </VerticalFlex>
        )}

        {requisition.status === GoCardlessRequisitionStatus.EX && (
          <VerticalFlex className={"pointer-events-none opacity-25"}>
            {requisition.bankAccounts.map((b) => {
              return (
                <BankAccountDisplay
                  key={b.id}
                  account={b}
                  lastSync={requisition.lastSync}
                />
              );
            })}
          </VerticalFlex>
        )}

        {requisition.status !== GoCardlessRequisitionStatus.EX &&
          requisition.status !== GoCardlessRequisitionStatus.LN && (
            <VerticalFlex>
              <span>{t("component.bankSettings.cardFinishText")}</span>
              <Button
                className={"w-[180px]"}
                variant={"secondary"}
                onClick={finishConnection}
              >
                {t("component.bankSettings.requisitionCard.finish")}
              </Button>
            </VerticalFlex>
          )}
      </CardContent>
      {syncIsLoading && (
        <VerticalFlex
          className={"absolute left-0 top-0 h-full w-full backdrop-blur"}
          align={"center"}
          justify={"center"}
        >
          <HorizontalFlex>
            <Spinner size={32} />
          </HorizontalFlex>
          <span className={"text-sm text-foreground/70"}>
            {t("common.synchronize")}
          </span>
        </VerticalFlex>
      )}
    </Card>
  );
};

export const BankAccountDisplay: FC<{
  account: BankAccount;
  lastSync?: string;
}> = ({ account, lastSync }) => {
  const { t } = useTranslation();
  const { activeIdentity } = useLoggedIn();

  let userIsAdmin = activeIdentity.role === "owner";
  let userIsOwner =
    activeIdentity.id === account.accountOwner?.id || "Accountowner id";

  const [disableBankAccount] = useBankAccountDisableMutation();

  const [enableBankAccount] = useBankAccountEnableMutation();

  const { confirmDelete } = useConfirmModal();

  const onDisable = () => {
    confirmDelete({
      text: t("component.bankSettings.requisitionCard.disableAccountConfirm", {
        IBAN: formatIBAN(account?.IBAN),
      }),
      onAction: async () => {
        let res = await disableBankAccount(account);
        if ("data" in res) {
          toast({
            icon: Check,
            title: t("common.success"),
            description: t("component.bankSettings.disableBankAccountSuccess"),
          });
        }
      },
    });
  };

  const onEnable = () => {
    enableBankAccount(account).then((res) => {
      if ("data" in res) {
        toast({
          icon: Check,
          title: t("common.success"),
          description: t("component.bankSettings.enableBankAccountSuccess"),
        });
      }
    });
  };

  return (
    <HorizontalFlex
      align={"center"}
      className={account.disabled ? "text-foreground/30" : "text-foreground"}
    >
      <Avatar>
        <AvatarFallback>
          {(account.bankName || "B")[0].toUpperCase()}
          {(account.productName || "B")[0].toUpperCase()}
        </AvatarFallback>
      </Avatar>
      <VerticalFlex gap={0}>
        <div className={"font-semibold"}>{account.productName}</div>
        <div>{formatIBAN(account.IBAN)}</div>
        {!account.disabled && (
          <div>
            {t("common.updatedAgo", {
              time: formatUpdateAgo(lastSync ?? account.updatedAt),
            })}
          </div>
        )}
      </VerticalFlex>
      <FlexOne />
      {!account.disabled && (
        <Button
          className={"w-[180px]"}
          disabled={!(userIsAdmin || userIsOwner)}
          variant={"secondary"}
          onClick={onDisable}
        >
          {t("component.bankSettings.requisitionCard.disableAccount")}
        </Button>
      )}
      {account.disabled && (
        <Button
          className={"w-[180px] text-foreground"}
          disabled={!(userIsAdmin || userIsOwner)}
          variant={"outline"}
          onClick={onEnable}
        >
          {t("component.bankSettings.requisitionCard.enableAccount")}
        </Button>
      )}
    </HorizontalFlex>
  );
};
