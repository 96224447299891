import React, { useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Maximize2, Minimize2 } from "lucide-react";
import { blobUrl } from "@/api/reduxApi";
import { BiDocument } from "@/models/document";
import { useTranslation } from "react-i18next";
import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";

export const DocumentPreview = ({ document }: { document: BiDocument }) => {
  const { t } = useTranslation();
  const { mode, setMode } = useDocumentContext();
  const preview = useMemo(() => {
    const preview = [...(document.pagesPreview ?? [])];
    preview.sort((a, b) => a.pageNumber - b.pageNumber);
    return preview;
  }, [document.pagesPreview]);

  return (
    <>
      {preview.length === 0 && (
        <div
          className={"flex h-96 w-full items-center justify-center rounded-md"}
        >
          <span className={"opacity-50"}>{t("common.preview")}</span>
        </div>
      )}
      {preview.length > 0 && (
        <ScrollArea className={"mt-auto flex h-96"}>
          <div className="relative flex-shrink rounded-2xl transition-all duration-300 ease-in-out">
            {(mode === "preview" || mode === "small") && (
              <div className={"sticky top-0 p-2"}>
                <Button
                  variant="secondary"
                  size={"icon"}
                  type="button"
                  className="group flex"
                  onClick={() => {
                    setMode(mode === "preview" ? "small" : "preview");
                  }}
                >
                  {mode === "preview" ? (
                    <Minimize2
                      className={
                        "transition-all group-hover:rotate-90 group-hover:scale-75"
                      }
                    />
                  ) : (
                    <Maximize2
                      className={
                        "transition-all group-hover:rotate-90 group-hover:scale-125"
                      }
                    />
                  )}
                </Button>
              </div>
            )}

            <div className={"flex flex-col items-center"}>
              {preview.map((p) => (
                <img
                  key={p.id + "_preview"}
                  src={blobUrl(p.blob)}
                  className={"shadow-s m-4 shadow-black"}
                  style={{
                    maxWidth: "100%",
                  }}
                  alt={"page preview"}
                />
              ))}
            </div>
          </div>
        </ScrollArea>
      )}
      {/*<BigDocumentPreview document={document} />{" "}*/}
    </>
  );
};
