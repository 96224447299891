import { reduxApi } from "@/api/reduxApi";
import { Match } from "@/models/match";

export const matchingApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    createMatch: builder.mutation<
      Match,
      { transactionIds: string[]; documentIds: string[] }
    >({
      query: (body) => ({
        url: "match/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bank", "Document"],
    }),

    //delete match
    deleteMatch: builder.mutation<string, { id: string }>({
      query: (arg) => ({
        url: "match/" + arg.id,
        method: "DELETE",
      }),
      invalidatesTags: ["Bank", "Document"],
    }),
  }),
});
