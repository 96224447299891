import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import {
  DataTableMultiselect,
  SearchValues,
  SelectGroup,
} from "@/components/display/DataTableMultiselect";
import {
  FilterAnd,
  FilterEq,
  FilterInner,
  FilterNot,
  FilterOr,
  FilterParameters,
} from "@/api/types";
import {
  BiDocument,
  BiDocumentKind,
  simpleDateFrom,
  simpleDateToSimpleIso,
  UploadDocumentStatus,
} from "@/models/document";
import { DateRange } from "react-day-picker";
import { useDocumentFilterQuery } from "@/api/endpoints/documentApi";
import {
  DataTablePagination,
  DataTablePaginationElement,
} from "@/components/display/DataTablePagination";
import Spinner from "@/components/loading/spinner";
import { DataTableNoEntriesHandler } from "@/components/display/DataTableNoEntries";
import FileUpload from "@/feature/documents/components/FileUpload";
import { cn } from "@/lib/utils";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { DataTableSortSelector } from "@/components/display/DataTableSortSelector";
import { DatevExportDropDown } from "@/feature/documents/components/DatevExportDropDown";
import { Separator } from "@/components/ui/separator";
import BigDocumentPreview from "@/feature/documents/components/Document/BigDocumentPreview";
import DocumentItem from "./components/Document/DocumentItem";
import DocumentCard from "@/feature/documents/components/Card/DocumentCard";
import { DocumentContext } from "@/feature/documents/DocumentContextProvider";
import useDocumentNavigation from "@/util/useDocumentNavigation";
import { usePreventScroll } from "@/components/page/UsePreventScroll";
import { useDebounce } from "@/components/time/UseDebounce";

const DocumentsContent = () => {
  const { t } = useTranslation();
  const paginationRef = useRef<DataTablePaginationElement<BiDocument>>(null);

  const [filter, setFilter] = useState<FilterInner<BiDocument>>({});
  const [sort, setSort] = useState<[keyof BiDocument & string, "asc" | "desc"]>(
    ["createdAt", "desc"],
  );
  const [ocrPending, setOcrPending] = useState<boolean>(false);

  const [filterParams, setFilterParams] =
    useState<FilterParameters<BiDocument>>();
  const [dateRange, setDateRange] = useState<DateRange | undefined>();

  let { data: documentData, isLoading } = useDocumentFilterQuery(
    {
      sort: sort[0],
      direction: sort[1],
      ...filterParams,
      ...filter,
    },
    {
      skip: !filter || !filterParams,
      pollingInterval: ocrPending ? 2500 : undefined,
    },
  );

  const {
    selectedDocumentId,
    selectDocumentById,
    selectNextDocument,
    selectPreviousDocument,
    mode,
    setMode,
  } = useDocumentNavigation(documentData?.items ?? []);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!documentData) {
      setOcrPending(false);
    } else {
      setOcrPending(
        documentData.items.some(
          (i) =>
            i.uploadDocument?.ocrStatus === UploadDocumentStatus.Pending ||
            i.uploadDocument?.ocrStatus === UploadDocumentStatus.Processing,
        ),
      );
    }
  }, [documentData]);

  const selectGroups: SelectGroup<BiDocument>[] = [
    {
      key: "kind",
      label: t("model.document.kind.self"),
      values: [
        {
          value: "receipt",
          label: t("model.document.kind.receipt"),
        },
        {
          value: "invoice",
          label: t("model.document.kind.invoice"),
        },
      ],
    },
    {
      key: "match",
      label: t("model.transaction.status"),
      values: [
        {
          label: t("model.transaction.status_open"),
          value: "status_open",
          filter: FilterEq("matches.id" as any, null),
        },
        {
          label: t("model.transaction.status_booked"),
          value: "status_booked",
          filter: FilterEq("matches.amountOpen" as any, 0),
        },
        {
          label: t("model.transaction.status_partial"),
          value: "status_partial",
          filter: FilterAnd(
            FilterNot(FilterEq("matches.amountOpen" as any, 0)),
            FilterNot(FilterEq("matches.id" as any, null)),
          ),
        },
        {
          label: t("model.transaction.status_invalid"),
          value: "status_invalid",
          filter: FilterOr(
            FilterEq("invoiceNumber", null),
            FilterEq("invoiceNumber", ""),
            FilterEq("invoiceDate", null),
            FilterEq("invoiceDate", ""),
            FilterAnd(
              FilterEq("kind", BiDocumentKind.Invoice),
              FilterOr(
                FilterEq("recipient.name", ""),
                FilterEq("recipient.address", ""),
              ),
            ),

            FilterAnd(
              FilterEq("kind", BiDocumentKind.Receipt),
              FilterOr(
                FilterEq("issuer.name", ""),
                FilterEq("issuer.address", ""),
              ),
            ),

            FilterEq("derived.positionsValid", false),
            FilterEq("dirty", true),
          ),
        },
      ],
    },
  ];

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      selectNextDocument();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      selectPreviousDocument();
    } else if (e.key === "ArrowRight") {
      e.preventDefault();
      paginationRef.current?.nextPage();
      window.scrollTo(0, 0);
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      paginationRef.current?.prevPage();
      window.scrollTo(0, 0);
    }
  };

  function hideDocumentDrawer() {
    selectDocumentById(undefined);
  }

  const selectedDocument = documentData?.items.find(
    (i) => i.id === selectedDocumentId,
  );

  // basically  never loose focus
  useEffect(() => {
    containerRef.current?.focus({
      preventScroll: true,
    });
  }, []);

  useEffect(() => {
    containerRef.current?.focus({
      preventScroll: true,
    });
  }, [selectedDocument, mode]);

  let prevent = useDebounce(mode === "edit", 100);
  usePreventScroll(prevent, {
    min: (containerRef.current?.offsetTop || 0) + 16,
  });

  const [multiSelectValue, setMultiSelectValue] = useState<
    SearchValues | undefined
  >(undefined);

  const handleChildValueChange = (dateRange: DateRange, status: string) => {
    setMultiSelectValue({
      search: "",
      searchTags: ["match:" + status],
    });
    setDateRange(dateRange);
  };

  return (
    <DocumentContext.Provider
      value={{
        selectedDocumentId,
        selectDocumentById,
        selectNextDocument,
        selectPreviousDocument,
        mode,
        setMode,
      }}
    >
      <FileUpload>
        <VerticalFlex gap={8}>
          <HorizontalFlex
            className="mt-4 flex-row-reverse flex-wrap"
            align={"stretch"}
          >
            <HorizontalFlex className={"flex-1"}>
              <div className={"flex-1"}>
                <DateRangePicker
                  value={
                    dateRange && dateRange.from && dateRange.to
                      ? [dateRange.from, dateRange.to]
                      : undefined
                  }
                  initialSelected={false}
                  onUpdate={({ range }) => setDateRange(range)}
                  align="start"
                  locale="de-DE"
                  showCompare={false}
                />
              </div>
              <div>
                <DataTableSortSelector
                  options={[
                    {
                      label: t("model.document.createdAt"),
                      value: "createdAt",
                    },
                    {
                      label: t("model.document.invoiceDate"),
                      value: "invoiceDate",
                    },
                    {
                      label: t("model.document.invoiceNumber"),
                      value: "invoiceNumber",
                    },
                  ]}
                  onChange={setSort}
                  default={sort}
                />
              </div>
              <Separator orientation="vertical" />
              <DatevExportDropDown
                onValueChange={handleChildValueChange}
                disabled={
                  documentData?.items ? documentData.items.length <= 0 : true
                }
              ></DatevExportDropDown>
            </HorizontalFlex>
            <HorizontalFlex style={{ flex: 1000000 }}>
              <DataTableMultiselect
                value={multiSelectValue}
                onChange={setMultiSelectValue}
                additionalFilters={
                  dateRange
                    ? [
                        {
                          kind: "range",
                          attribute: "invoiceDate",
                          lower: dateRange.from
                            ? simpleDateToSimpleIso(
                                simpleDateFrom(dateRange.from),
                              )
                            : undefined,
                          upper: dateRange.to
                            ? simpleDateToSimpleIso(
                                simpleDateFrom(dateRange.to),
                              )
                            : undefined,
                        },
                      ]
                    : undefined
                }
                textSearchKeys={[
                  "invoiceNumber",
                  "issuer.name",
                  "issuer.address",
                  "issuer.taxId",
                  "issuer.iban",
                  "recipient.name",
                  "recipient.address",
                  "recipient.taxId",
                  "recipient.iban",
                ]}
                selectGroups={selectGroups}
                placeholder={t("ui.multipleSelector.documents.table")}
                onFilterChange={setFilter}
              />
            </HorizontalFlex>
          </HorizontalFlex>
          <HorizontalFlex gap={4}>
            <VerticalFlex
              style={{ flex: 2 }}
              gap={8}
              className={"flex-auto transition-all duration-300"}
            >
              <div
                ref={containerRef}
                tabIndex={0}
                onKeyDown={onKeyDown}
                style={{
                  outline: "none",
                  display: "grid",
                  gridTemplateRows: "100%",
                  gridTemplateColumns: "1fr",
                }}
              >
                <VerticalFlex
                  style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 2,
                    gridRowStart: 1,
                    gridRowEnd: 2,
                  }}
                  className={cn(
                    selectedDocument &&
                      (mode === "preview" ||
                        mode === "edit" ||
                        mode === "edit-saved") &&
                      "blur-md",
                  )}
                >
                  <DataTableNoEntriesHandler result={documentData} />
                  {isLoading && <Spinner />}
                  {!isLoading &&
                    (documentData?.items ?? []).map((i) => (
                      <DocumentItem
                        key={i.id}
                        document={i}
                        highlight={selectedDocumentId === i.id}
                        onClick={() => {
                          if (selectedDocumentId === i.id) {
                            hideDocumentDrawer();
                          } else {
                            selectDocumentById(i.id);
                          }
                        }}
                      />
                    ))}
                  <DataTablePagination
                    ref={paginationRef}
                    defaultPageSize={10}
                    result={documentData}
                    onChange={setFilterParams}
                  />
                </VerticalFlex>
                {selectedDocument &&
                  (mode === "preview" ||
                    mode === "edit" ||
                    mode === "edit-saved") && (
                    <div
                      tabIndex={0}
                      onKeyDown={onKeyDown}
                      className="no-outline"
                      style={{
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                        gridRowStart: 1,
                        gridRowEnd: 2,
                      }}
                    >
                      <BigDocumentPreview
                        document={selectedDocument as BiDocument}
                      />
                    </div>
                  )}
              </div>
            </VerticalFlex>
            <VerticalFlex
              style={{
                flexBasis: "600px",
                minWidth: "500px",
              }}
              className={cn(
                "transition-shadow duration-300 hover:shadow-lg",
                selectedDocumentId ? "flex" : "hidden",
              )}
              gap={8}
              tabIndex={0}
            >
              <DocumentCard
                key={selectedDocumentId + "_card"}
                biDocument={selectedDocument as BiDocument}
              />
            </VerticalFlex>
          </HorizontalFlex>
        </VerticalFlex>
      </FileUpload>
    </DocumentContext.Provider>
  );
};

export default DocumentsContent;
