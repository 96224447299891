import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { useTranslation } from "react-i18next";
import {
  BiDocument,
  BiDocumentKind,
  UploadDocumentStatus,
} from "@/models/document";

import { BookingBadge } from "@/feature/documents/components/BookingBadge";
import { formatMoney, formatSimpleDate } from "@/util/format";
import KindBadge from "./KindBadge";
import FakeProgressBar from "@/feature/documents/components/Document/FakeProgressBar";
import DocumentField from "@/feature/documents/components/Document/DocumentField";
import { cn } from "@/lib/utils";
import { FileWarning } from "lucide-react";
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from "@/components/ui/tooltip";

interface DocumentItemProps {
  document: BiDocument;
  highlight?: boolean;
  onClick?: (document: BiDocument) => void;
}

const DocumentItem = ({
  document,
  highlight = false,
  onClick,
}: DocumentItemProps) => {
  const { t } = useTranslation();
  const processing =
    document.uploadDocument?.ocrStatus === UploadDocumentStatus.Processing ||
    document.uploadDocument?.ocrStatus === UploadDocumentStatus.Pending;

  return (
    <HorizontalFlex
      id={document.id}
      key={document.id}
      className={cn(
        "cursor-pointer overflow-hidden rounded-xl bg-background",
        highlight
          ? "rounded-r-none border-2 border-background bg-muted/80 shadow hover:bg-muted"
          : "bg-muted/40 hover:bg-muted/50",
      )}
      style={{
        transition: "all 150ms ease-in-out",
        marginLeft: highlight ? "-16px" : "0px",
        marginRight: highlight ? "-8px" : "0px",
      }}
      onClick={() => onClick?.(document)}
      gap={0}
    >
      <KindBadge kind={document.kind} processing={processing} sideways />
      <VerticalFlex className="flex-1">
        {processing && (
          <FakeProgressBar document={document} eta={20} spread={2} />
        )}
        <HorizontalFlex>
          <VerticalFlex gap={2} className="p-6">
            <HorizontalFlex>
              <DocumentField
                processing={processing}
                label={t("model.document.invoiceNumber")}
                value={document.invoiceNumber}
              />
            </HorizontalFlex>
            <FlexOne />
            {document.kind === BiDocumentKind.Receipt && (
              <DocumentField
                processing={processing}
                width={15}
                label={t("model.document.issuerSimple")}
                value={document.issuer.name}
              />
            )}
            {document.kind === BiDocumentKind.Invoice && (
              <DocumentField
                processing={processing}
                width={15}
                label={t("model.document.recipientSimple")}
                value={document.recipient.name}
              />
            )}
          </VerticalFlex>
          <FlexOne />
          <VerticalFlex gap={2} align="end" className="p-6">
            <DocumentField
              processing={processing}
              className="opacity-90"
              label={t("model.document.invoiceDate")}
              value={formatSimpleDate(document.invoiceDate)}
            />
            <FlexOne />
            <HorizontalFlex>
              <DocumentField
                processing={processing}
                className="font-semibold"
                width={5.5}
                label={t("model.document.totalAmount")}
                value={
                  document.derived.totalAmount &&
                  formatMoney(document.derived.totalAmount)
                }
              />
              <BookingBadge item={document} />
            </HorizontalFlex>
          </VerticalFlex>
        </HorizontalFlex>
      </VerticalFlex>
      {document.dirty && (
        <div
          className={
            "align-center flex h-full justify-center rounded-r-xl bg-red-600"
          }
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <FileWarning className={"m-auto w-16 text-foreground"} />
            </TooltipTrigger>
            <TooltipContent>
              <div className={"w-56"}>
                {t("model.document.dirty.description")}
              </div>
            </TooltipContent>
          </Tooltip>
        </div>
      )}
    </HorizontalFlex>
  );
};

export default DocumentItem;
