import React, { useEffect, useRef } from "react";
import { UseFormReturn } from "react-hook-form";
import { CompanyUpdateData } from "@/models/company";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { t } from "i18next";
import { Input } from "@/components/ui/input";
import { FlexOne, VerticalFlex } from "@/components/layout/Flex";
import { electronicFormatIBAN, isValidIBAN } from "ibantools";
import { calculateCursorPosition, formatIBAN } from "@/util/format";
import BankNameComboBox from "@/feature/settings/UserSettings/form/optional/BankNameComboBox";

const BankDetailsFormFields = ({
  form,
}: {
  form: UseFormReturn<CompanyUpdateData>;
}) => {
  const { watch, setValue } = form;
  const iban = watch("bankDetails.iban");

  const ibanInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (iban) {
      const ibanWithoutSpaces = iban.replace(/\s+/g, "");

      if (isValidIBAN(ibanWithoutSpaces)) {
        const electronicIban = electronicFormatIBAN(ibanWithoutSpaces);
        if (electronicIban?.startsWith("DE")) {
          const blz = electronicIban.substring(4, 12);
          setValue("bankDetails.blz", blz);
        }
      }
    } else {
      setValue("bankDetails.blz", "");
    }
  }, [iban, setValue, form]);

  const handleIbanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { selectionStart } = e.target;
    const rawValue = e.target.value;
    const formattedValue = formatIBAN(rawValue);

    const cursorPosition = calculateCursorPosition(
      rawValue,
      formattedValue,
      selectionStart || 0,
    );

    setValue("bankDetails.iban", formattedValue);

    setTimeout(() => {
      ibanInputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  return (
    <VerticalFlex>
      <div className="grid grid-cols-3 gap-4">
        <BankNameComboBox form={form} />
        <FormField
          control={form.control}
          name="bankDetails.accountHolder"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("component.companyForm.bankDetails.accountHolder")}
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />{" "}
        <FlexOne />
        <FormField
          control={form.control}
          name="bankDetails.iban"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("component.companyForm.bankDetails.iban")}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  ref={ibanInputRef}
                  onChange={handleIbanChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="bankDetails.blz"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("component.companyForm.bankDetails.blz")}
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />{" "}
      </div>
    </VerticalFlex>
  );
};

export default BankDetailsFormFields;
