import React from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { CreateInvoiceComboButton } from "@/feature/invoices/create/CreateInvoiceComboButton";
import InvoiceTable from "@/feature/invoices/read/InvoiceTable";
import { t } from "i18next";
import { FeatureFlags } from "@/models/user";
import WaitingForInvoices from "@/feature/invoices/WaitingForInvoices";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import GuardConditionCheck from "@/feature/invoices/Guard";

const Invoices = () => {
  const hasInvoiceFeature = useFeatureFlag(FeatureFlags.Invoice);

  if (hasInvoiceFeature) {
    return (
      <VerticalFlex gap={8}>
        <GuardConditionCheck>
          <HorizontalFlex className="pb-1">
            <Title>{t("component.invoices.my")}</Title>
            <FlexOne />
            <CreateInvoiceComboButton />
          </HorizontalFlex>
          <InvoiceTable />
        </GuardConditionCheck>
      </VerticalFlex>
    );
  } else {
    return <WaitingForInvoices />;
  }
};

export default Invoices;
