import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { t } from "i18next";
import { CompanyUpdateData } from "@/models/company";
import { LegalFormComboBox } from "@/feature/settings/UserSettings/form/optional/LegalFormComboBox";
import { cn } from "@/lib/utils";
import { HorizontalFlex } from "@/components/layout/Flex";
import CompanyImageUploadForm from "@/feature/settings/UserSettings/form/CompanyImageUploadForm";
import { Info } from "lucide-react";

const GeneralInfoFormField = ({
  form,
  className,
}: {
  form: UseFormReturn<CompanyUpdateData>;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "grid grid-cols-[auto_15rem_1fr_auto] items-center gap-4",
        className,
      )}
    >
      {/*Company image*/}
      <CompanyImageUploadForm />

      {/*Form*/}
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel className={"font-semibold"}>
              {t("component.companyForm.generalInfo.name")} *
              <FormMessage />
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <HorizontalFlex className="pt-6">
        <LegalFormComboBox form={form} />
      </HorizontalFlex>

      {/*Info*/}
      <HorizontalFlex align={"center"} className={"pt-6 text-muted-foreground"}>
        <span className="text-right text-xs">
          {t("component.companyForm.information")}
        </span>
        <Info size={40} />
      </HorizontalFlex>
    </div>
  );
};

export default GeneralInfoFormField;
