import { HorizontalFlex } from "@/components/layout/Flex";
import { InvoiceDraft } from "@/models/invoice";
import { useInvoiceActionHandlers } from "@/feature/invoices/read/_table/useInvoiceActionHandlers";
import InvoiceActionButton from "@/feature/invoices/read/_table/InvoiceActionButton";
import { Copy, Ellipsis, Pencil, Trash, Undo } from "lucide-react";
import { t } from "i18next";
import InvoiceDropdownActions from "@/feature/invoices/read/_table/InvoiceDropdownActions";
import { preventBubbling } from "@/lib/utils";
import { Menubar, MenubarMenu, MenubarTrigger } from "@/components/ui/menubar";
import DownloadDocumentInvoice from "@/feature/invoices/read/_table/DownloadDocumentInvoice";

export const InvoiceActions = ({
  invoice,
  setSelectedInvoiceId,
  selectedInvoiceId,
}: {
  invoice: InvoiceDraft;
  setSelectedInvoiceId: (
    value:
      | ((prevState: string | undefined) => string | undefined)
      | string
      | undefined,
  ) => void;
  selectedInvoiceId: string | undefined;
}) => {
  const {
    handlers: { handleEdit, handleDuplicate, handleDelete, handleCancellation },
    states: { isCancelling, isDuplicating, isEditable, isCancellable },
  } = useInvoiceActionHandlers({
    invoice,
    setSelectedInvoiceId,
    selectedInvoiceId,
  });

  // todo maybe later add the compact view, pm wishes to have the actions in a dropdown
  // in most cases we actually have enough space to show all actions...
  // const isCompactView = selectedInvoiceId !== undefined;
  const isCompactView = false;

  if (!isCompactView) {
    return (
      <HorizontalFlex className="gap-2">
        {isEditable ? (
          <InvoiceActionButton
            onClick={handleEdit}
            icon={<Pencil size={16} />}
            label={t("component.invoices.table.edit")}
          />
        ) : (
          <DownloadDocumentInvoice invoice={invoice} />
        )}

        {!isEditable && isCancellable && invoice.typeCode !== "384" && (
          <InvoiceActionButton
            onClick={handleCancellation}
            icon={<Undo size={16} />}
            label={t("component.invoices.table.cancel")}
            disabled={isCancelling}
            loading={isCancelling}
          />
        )}

        {invoice.typeCode === "380" && (
          <InvoiceActionButton
            onClick={handleDuplicate}
            icon={<Copy size={16} />}
            label={t("component.invoices.table.duplicate")}
            disabled={isDuplicating}
            loading={isDuplicating}
          />
        )}

        {isEditable && (
          <InvoiceActionButton
            onClick={handleDelete}
            icon={<Trash size={16} />}
            label={t("component.invoices.table.delete")}
          />
        )}
      </HorizontalFlex>
    );
  }

  return (
    <Menubar className="inline-flex text-muted-foreground">
      <MenubarMenu>
        {isEditable ? (
          <MenubarTrigger onClick={handleEdit}>
            <InvoiceActionButton
              onClick={handleEdit}
              icon={<Pencil size={16} />}
              label={t("component.invoices.table.edit")}
            />
          </MenubarTrigger>
        ) : (
          <MenubarTrigger>
            <DownloadDocumentInvoice invoice={invoice} />
          </MenubarTrigger>
        )}
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger onClick={preventBubbling(() => {})}>
          <Ellipsis className="ml-auto" />
        </MenubarTrigger>

        <InvoiceDropdownActions
          invoice={invoice}
          onEdit={handleEdit}
          onDuplicate={handleDuplicate}
          onCancel={handleCancellation}
          onDelete={handleDelete}
          isEditable={isEditable}
          isCancelling={isCancelling}
          isCancellable={isCancellable}
          isDuplicating={isDuplicating}
        />
      </MenubarMenu>
    </Menubar>
  );
};

export default InvoiceActions;
