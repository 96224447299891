import { InvoiceDraft } from "@/models/invoice";
import { ReactNode } from "react";
import { useGetDocumentByIdQuery } from "@/api/endpoints/documentApi";
import { blobUrl } from "@/api/reduxApi";
import { HorizontalFlex } from "@/components/layout/Flex";
import { DownloadIcon } from "lucide-react";

function DownloadDocumentInvoice({
  invoice,
  label,
}: {
  invoice: InvoiceDraft;
  label?: ReactNode;
}) {
  const { data, isLoading } = useGetDocumentByIdQuery(invoice.documentId);
  if (isLoading || data === undefined) return null;

  const blobData = data.uploadDocument?.blob;
  if (!blobData) {
    return null;
  }
  const url = blobUrl(blobData);

  return (
    <a download={true} target={"_blank"} href={url}>
      <HorizontalFlex>
        <DownloadIcon size={16} />
        {label && label}
      </HorizontalFlex>
    </a>
  );
}

export default DownloadDocumentInvoice;
