import React, {useState} from "react";
import {UseFormReturn} from "react-hook-form";
import {FlexOne, HorizontalFlex} from "@/components/layout/Flex";
import CountrySelect from "@/components/form/CountrySelect";
import {ListPlus, MapPin} from "lucide-react";
import {Button} from "@/components/ui/button";
import {t} from "i18next";
import {FormSimpleText} from "@/components/form/FormSimpleText";
import {CompanyUpdateData} from "@/models/company";

interface AddressFormFieldsProps {
    form: UseFormReturn<CompanyUpdateData>;
}

export const AddressFormFields = ({form}: AddressFormFieldsProps) => {
    const [showLineTwo, setShowLineTwo] = useState(false);
    const [showLineThree, setShowLineThree] = useState(false);
    return (
        <div className="grid grid-cols-1 gap-3">
            <MapPin className={"ml-auto h-6 text-muted-foreground/10"}/>
            <FlexOne></FlexOne>

            <HorizontalFlex>
                <FormSimpleText
                    form={form}
                    name={`address.postcodeCode`}
                    label="Postleitzahl"
                />
                <FormSimpleText
                    form={form}
                    name={`address.cityName`}
                    label="Ort"
                />
                <CountrySelect form={form} name={`address.countryCode`}/>
            </HorizontalFlex>
            <FormSimpleText
                form={form}
                name={`address.lineOne`}
                label={t("component.companyForm.address.lineOne")}
            />
            {!showLineTwo && (
                <Button
                    size={"icon"}
                    variant={"ghost"}
                    type={"button"}
                    className={"text-muted-foreground"}
                    onClick={() => setShowLineTwo(true)}
                >
                    <ListPlus/>
                </Button>
            )}
            {showLineTwo && (
                <>
                    <FormSimpleText
                        form={form}
                        name={`address.lineTwo`}
                        placeholder={t("component.companyForm.address.lineTwo")}
                    />
                    {!showLineThree && (
                        <Button
                            size={"icon"}
                            variant={"ghost"}
                            type={"button"}
                            className={"text-muted-foreground"}
                            onClick={() => setShowLineThree(true)}
                        >
                            <ListPlus/>
                        </Button>
                    )}
                </>
            )}
            {showLineThree && (
                <FormSimpleText
                    form={form}
                    name={`address.lineThree`}
                    placeholder={t("component.companyForm.address.lineTwoPlaceholder")}
                />
            )}
        </div>
    );
};
