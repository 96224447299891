import { reduxApi } from "@/api/reduxApi";
import {
  CreateInvoiceRequest,
  InvoiceDraft,
  InvoiceRequest,
} from "@/models/invoice";
import { DeepPartial } from "react-hook-form";
import { Filter, FilterResult } from "@/api/types";
import { BiDocument } from "@/models/document";

export const invoiceApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    //

    preview: builder.query<
      string,
      { id: string; body: DeepPartial<InvoiceRequest> }
    >({
      query: ({ id, body }) => ({
        url: `invoice/${id}/preview`,
        method: "POST",
        body,
      }),
      providesTags: ["Invoice"],
    }),
    //

    createInvoiceDraft: builder.mutation<InvoiceDraft, CreateInvoiceRequest>({
      query: (body) => ({
        url: "invoice/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Invoice"],
    }),
    //

    getInvoice: builder.query<InvoiceDraft, string>({
      query: (id) => ({
        url: `invoice/${id}`,
        method: "GET",
      }),
      transformResponse: (
        response: InvoiceDraft & { draftRequest: string | InvoiceRequest },
      ) => {
        return {
          ...response,
          draftRequest:
            typeof response.draftRequest === "string"
              ? (JSON.parse(response.draftRequest) as InvoiceRequest)
              : response.draftRequest,
        };
      },
      providesTags: ["Invoice"],
    }),
    //

    filter: builder.query<FilterResult<InvoiceDraft>, Filter<InvoiceDraft>>({
      query: (body) => ({
        url: `invoice/filter`,
        method: "POST",
        body: body,
      }),
      providesTags: ["Invoice"],
    }),
    //

    update: builder.mutation<
      InvoiceDraft,
      { id: string; body: DeepPartial<InvoiceRequest> }
    >({
      query: ({ id, body }) => ({
        url: `invoice/${id}`,
        method: "PUT",
        body: body,
      }),
      transformResponse: (
        response: InvoiceDraft & { draftRequest: string | InvoiceRequest },
      ) => {
        return {
          ...response,
          draftRequest:
            typeof response.draftRequest === "string"
              ? (JSON.parse(response.draftRequest) as InvoiceRequest)
              : response.draftRequest,
        };
      },
      invalidatesTags: ["Invoice"],
    }),
    //

    delete: builder.mutation<InvoiceDraft, string>({
      query: (id) => ({
        url: `invoice/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invoice"],
    }),
    //

    finalize: builder.mutation<
      BiDocument,
      { id: string; body: DeepPartial<InvoiceRequest> }
    >({
      query: ({ id, body }) => ({
        url: `invoice/${id}/finalize`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Invoice"],
    }),
  }),
});
