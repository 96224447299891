import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";

import React from "react";
import { cn } from "@/lib/utils";
import {
  FormSimpleNumber,
  FormSimpleNumberProps,
} from "@/components/form/FormSimpleNumber";
import { formatNumberFancy, parseNumber } from "@/util/format";

export const FormSimpleMoney = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  props: FormSimpleNumberProps<T, TName>,
) => {
  return (
    <FormSimpleNumber
      postfix={(value) => (
        <span className={cn("ml-3 pt-0.5", value < 0 ? "text-red-300" : "")}>
          {"€"}
        </span>
      )}
      valueDisplay={(value) => formatNumberFancy(value)}
      parseNumber={parseNumber}
      {...props}
    />
  );
};
