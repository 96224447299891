import React, { useEffect } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { ArrowLeftRight } from "lucide-react";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { InvoiceRequest } from "@/models/invoice";
import LineItem from "@/feature/invoices/edit/form/components/items/LineItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const LineItemsFormFields = React.memo(
  ({ form }: { form: UseFormReturn<InvoiceRequest> }) => {
    const { control } = form;
    const { fields, append, remove, move } = useFieldArray({
      control,
      name: "items",
    });

    const [disableZeroPercent, setDisableZeroPercent] = React.useState(false);

    useEffect(() => {
      form.setValue(
        "priceType",
        form.getValues("priceType") === "net" ? "net" : "gross",
      );
    }, [form]);

    useEffect(() => {
      const hasHigherTax = fields.some(
        (item, index) => item.taxRate === "19" || item.taxRate === "7",
      );
      setDisableZeroPercent(hasHigherTax);
    }, [fields, form]);

    const handleDragEnd = React.useCallback(
      (result: any) => {
        const { destination, source } = result;
        if (!destination) return;
        if (destination.index !== source.index) {
          move(source.index, destination.index);

          const updatedFields = [...fields];
          updatedFields.forEach((item, index) => {
            form.setValue(`items.${index}.position`, (index + 1).toString());
          });
        }
      },
      [move, fields, form],
    );

    const getItemStyle = (isDragging: boolean) => ({
      background: isDragging ? "rgba(40, 40, 40, 0.7)" : "none",
      backdropFilter: isDragging ? "blur(8px)" : "none",
      boxShadow: isDragging ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
      transition: "background 0.2s ease, box-shadow 0.2s ease",
    });

    const getListStyle = (isDraggingOver: boolean) => ({
      background: isDraggingOver ? "" : "none",
      padding: "8px",
      transition: "background 0.2s ease",
    });

    const defaultValues = {
      unit: "C62",
      name: "",
      quantity: "1,00",
      unitPrice: 0,
      taxRate: "19",
      discount: "0",
      position: fields.length + 1 + "",
    };

    return (
      <VerticalFlex gap={4}>
        <HorizontalFlex gap={0}>
          <Button
            variant={"link"}
            className={
              "ml-0 pl-0 text-muted-foreground/60 hover:text-muted-foreground"
            }
            onClick={(e) => {
              e.preventDefault();
              form.setValue(
                "priceType",
                form.getValues("priceType") === "net" ? "gross" : "net",
              );
            }}
          >
            <span className={"text-primary"}>
              {`${form.getValues("priceType") === "net" ? "Netto" : "Brutto"}preis`}{" "}
            </span>
            <span className={"ml-2 flex items-center text-xs"}>
              <ArrowLeftRight size={12} className={"ml-0 mr-1"} />
              {`${form.getValues("priceType") === "gross" ? "Netto" : "Brutto"}`}{" "}
            </span>
          </Button>
        </HorizontalFlex>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="lineItems">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className={"flex flex-col gap-2"}
              >
                {fields.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...getItemStyle(snapshot.isDragging),
                          ...provided.draggableProps.style,
                        }}
                        className={"group rounded"}
                      >
                        <LineItem
                          item={item}
                          index={index}
                          form={form}
                          defaultValues={defaultValues}
                          control={control}
                          remove={remove}
                          append={append}
                          disableZeroPercent={disableZeroPercent}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </VerticalFlex>
    );
  },
);

export default LineItemsFormFields;
