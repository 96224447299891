export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  firstname: string;
  lastname: string;
  mail: string;
  featureFlags?: FeatureFlags[];
}

export interface UserUpdateRequest {
  firstname: string;
  lastname: string;
  mail: string;
}

export enum FeatureFlags {
  DatevBookings = "datev-bookings",
  OCRDownload = "ocr-download",
  Invoice = "invoice",
}
