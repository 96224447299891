import React, { useEffect } from "react";
import { useParams } from "react-router";
import { InvoiceLayout, useInvoicePreview } from "./InvoiceLayout";
import { InvoiceRequest } from "@/models/invoice";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import { Textarea } from "@/components/ui/textarea";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CalendarIcon, Mail, MailOpen } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { de } from "date-fns/locale";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { t } from "i18next";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { ConfirmFinalizeCancelInvoice } from "@/feature/invoices/edit/ConfirmFinalizeInvoice";
import { useNavigate } from "react-router-dom";
import { convertDate, convertSimpleDate } from "@/util/date";
import { toast } from "@/components/ui/use-toast";
import { useDebounce } from "@/util/debounce";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableRow,
} from "@/components/ui/table";

const CancelInvoice = () => {
  const { id } = useParams();
  const { data } = invoiceApi.useGetInvoiceQuery(id as string);
  const [finalize] = invoiceApi.useFinalizeMutation();

  const { preview, setPreview } = useInvoicePreview();
  const [triggerPreview] = invoiceApi.useLazyPreviewQuery();
  const navigate = useNavigate();
  const [requestBody, setRequestBody] = React.useState<InvoiceRequest | null>(
    null,
  );

  const debouncedRequestBody = useDebounce(requestBody, 700);

  useEffect(() => {
    if (debouncedRequestBody && requestBody && id) {
      triggerPreview({
        id: id,
        body: requestBody,
      }).then((response) => {
        if (response.data) {
          setPreview(response.data);
        }
      });
    }
  }, [id, triggerPreview, setPreview, debouncedRequestBody]);

  useEffect(() => {
    if (data?.draftRequest) {
      setRequestBody(data.draftRequest as InvoiceRequest);
    }
  }, [data]);

  const updateNotes = (notes: string) => {
    setRequestBody((prev) => (prev ? { ...prev, note: notes } : null));
  };

  const updateDate = (date: Date | undefined) => {
    if (date) {
      const invoiceDate = convertDate(date);
      setRequestBody((prev) =>
        prev ? { ...prev, issueDate: invoiceDate } : null,
      );
    }
  };

  return (
    <InvoiceLayout preview={preview}>
      <VerticalFlex className={"flex-1"}>
        <HorizontalFlex
          className={cn(
            "sticky top-16 z-20 mb-4 rounded-xl border bg-muted/60 px-4 py-1 shadow-background backdrop-blur-md transition-all",
          )}
        >
          <VerticalFlex justify={"center"}>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink href="/invoices">
                    {t("component.app.sidebar.invoice")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink>
                    {t("component.invoices.editInv", {
                      invoiceNumber: data?.invoiceNumber,
                    })}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </VerticalFlex>
          <HorizontalFlex className={"ml-auto"}>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  type="button"
                  variant={"default"}
                  className={cn(
                    "card-shine-effect group relative overflow-hidden p-3 pl-7 hover:animate-gradientShift",
                  )}
                >
                  <Mail
                    size={16}
                    className="absolute left-4 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-100 transform text-purple-900/50 opacity-0 transition-all duration-1000 ease-in-out group-hover:rotate-12 group-hover:scale-125 group-hover:opacity-100"
                  />
                  <MailOpen
                    size={16}
                    className="absolute left-4 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-purple-500 transition-all duration-200 ease-in-out group-hover:translate-y-[-10px] group-hover:opacity-0"
                  />
                  {t("common.complete")}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {t("component.invoices.confirmTitle")}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    <ConfirmFinalizeCancelInvoice />
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => {
                      if (requestBody) {
                        finalize({
                          id: data?.id!,
                          body: requestBody,
                        }).then((response) => {
                          if ("data" in response) {
                            toast({
                              icon: Mail,
                              title: t("common.success"),
                              description: t(
                                "component.invoices.finalizeSuccess",
                              ),
                            });
                          }
                        });
                        navigate(`/inv/${data?.id}/finalize`);
                      }
                    }}
                  >
                    {t("common.confirm")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </HorizontalFlex>
        </HorizontalFlex>
        <h2> Rechnung stornieren</h2>
        <Table>
          <TableCaption>
            {t("component.invoices.editInv", {
              invoiceNumber: data?.invoiceNumber,
            })}
          </TableCaption>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">
                {" "}
                <Label htmlFor={"issueDate"}>Rechnungsdatum</Label>
              </TableCell>
              <TableCell>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id={"issueDate"}
                      variant={"outline"}
                      className={cn(
                        "w-[280px] justify-start text-left font-normal",
                        !requestBody?.issueDate && "text-muted-foreground",
                      )}
                    >
                      <HorizontalFlex>
                        <CalendarIcon />
                        {requestBody?.issueDate ? (
                          format(
                            convertSimpleDate(requestBody.issueDate),
                            "PPP",
                            {
                              locale: de,
                            },
                          )
                        ) : (
                          <span>Rechnungsdatum wählen</span>
                        )}
                      </HorizontalFlex>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="flex w-auto flex-col space-y-2 p-2">
                    <div className="rounded-md border">
                      <Calendar
                        mode="single"
                        selected={
                          requestBody?.issueDate
                            ? convertSimpleDate(requestBody.issueDate)
                            : undefined
                        }
                        onSelect={updateDate}
                      />
                    </div>
                  </PopoverContent>
                </Popover>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Label htmlFor={"note"}>Notizen</Label>
              </TableCell>
              <TableCell>
                <Textarea
                  id={"note"}
                  placeholder="Zusätzliche Notizen"
                  className={"max-h-48"}
                  value={requestBody?.note || ""}
                  onChange={(e) => updateNotes(e.target.value)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </VerticalFlex>
    </InvoiceLayout>
  );
};

export default CancelInvoice;
