import React from "react";
import { HorizontalFlex } from "@/components/layout/Flex";
import { BuyerInfoFormFields } from "@/feature/invoices/edit/form/components/buyer/BuyerInfoFormFields";
import { UseFormReturn } from "react-hook-form";
import { InvoiceRequest } from "@/models/invoice";
import { Card } from "@/components/ui/card";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { CircleUserRound } from "lucide-react";
import { ContactTypeComponent } from "@/feature/contacts/Contacts";
import { useFilterContactsQuery } from "@/api/endpoints/contactsApi";
import { t } from "i18next";

interface BuyerAccordionItemProps {
  form: UseFormReturn<InvoiceRequest>;
}

const BuyerCard = ({ form }: BuyerAccordionItemProps) => {
  const customerNumber = form.getValues("buyer.customerNumber");
  const contacts = useFilterContactsQuery({
    sort: "companyName",
    direction: "asc",
  }).data?.items;

  return (
    <AccordionItem value={"buyer"}>
      <AccordionTrigger chevronSize={8}>
        <HorizontalFlex align={"center"} className={"text-left"}>
          {customerNumber ? (
            <ContactTypeComponent
              hasLabel={false}
              type={
                contacts?.find((i) => i.customerNumber === customerNumber)?.type
              }
              size={24}
            />
          ) : (
            <CircleUserRound />
          )}

          <h4 className={"text-xl"}> {t("component.invoices.recipient")}</h4>
        </HorizontalFlex>
      </AccordionTrigger>
      <AccordionContent>
        <Card className={"backdrop-blur/60 relative rounded bg-card/90 p-8"}>
          <BuyerInfoFormFields form={form} />
        </Card>
      </AccordionContent>
    </AccordionItem>
  );
};

export default BuyerCard;
