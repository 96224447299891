import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import {
  DataTableMultiselect,
  SelectGroup as SelectGroupMultiselect,
} from "@/components/display/DataTableMultiselect";
import React, { useEffect, useState } from "react";
import { useDocumentFilterQuery } from "@/api/endpoints/documentApi";
import { matchingApi } from "@/api/endpoints/matchingApi";
import { FilterAnd, FilterEq, FilterInner } from "@/api/types";
import { BiDocument } from "@/models/document";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { BankTransaction } from "@/models/bankTransaction";
import { removeDuplicates, removeDuplicatesByKey } from "@/util/unique";
import { useGetDocumentMatchSuggestionsByBankTransactionQuery } from "@/api/endpoints/documentProcessingApi";
import { SmallDocumentPreviewCard } from "@/feature/transactions/components/SmallDocumentPreviewCard";

interface TransactionDocumentEmptyCardProps {
  transaction: BankTransaction;
  document?: BiDocument;
}

export const TransactionDocumentEmptyCard = ({
  transaction,
  document,
}: TransactionDocumentEmptyCardProps) => {
  const { t } = useTranslation();

  const [selectedDocumentId, setSelectedDocumentId] = useState<string>("");

  const [filter, setFilter] = useState<FilterInner<BiDocument>>();

  const filterActive = Object.keys(filter || {}).length > 0;

  const { data: documentData } = useDocumentFilterQuery({
    sort: "createdAt",
    direction: "desc",
    limit: 5,
    ...FilterAnd(
      FilterEq("matches.id" as any, null),
      ...(filter ? [filter] : []),
    ),
  });

  const { data: docMatches } =
    useGetDocumentMatchSuggestionsByBankTransactionQuery({
      id: transaction.id,
    });

  let shownDocuments = removeDuplicatesByKey(
    (!filterActive && docMatches ? docMatches : [])
      .map((doc) => ({ document: doc.document, highlight: true }))
      .concat(
        (documentData?.items || []).map((doc) => ({
          document: doc,
          highlight: false,
        })),
      ),
    (d) => d.document.id,
  ).slice(0, 5);

  const [createMatch] = matchingApi.useCreateMatchMutation();
  const [deleteMatch] = matchingApi.useDeleteMatchMutation();

  const selectGroups: SelectGroupMultiselect<BiDocument>[] = [
    {
      key: "kind",
      label: t("model.document.kind.self"),
      values: [
        {
          value: "receipt",
          label: t("model.document.kind.receipt"),
        },
        {
          value: "invoice",
          label: t("model.document.kind.invoice"),
        },
      ],
    },
  ];

  const reset = () => {
    setSelectedDocumentId("");
  };

  const book = async () => {
    let matchedDocumentIds =
      transaction.match?.documents.map((d) => d.documentId) || [];

    if (transaction.match) {
      await deleteMatch(transaction.match).unwrap();
    }

    await createMatch({
      transactionIds: [transaction.id],
      documentIds: removeDuplicates([
        selectedDocumentId,
        ...matchedDocumentIds,
      ]),
    }).unwrap();
    reset();
  };

  useEffect(() => {
    reset();
  }, [transaction.id]);

  return (
    <VerticalFlex
      className={"rounded-[6px] border-[2px] border-foreground/20 p-4"}
    >
      {
        <HorizontalFlex>
          <DataTableMultiselect
            textSearchKeys={[
              "invoiceNumber",
              "issuer.name",
              "issuer.address",
              "issuer.taxId",
              "issuer.iban",
              "recipient.name",
              "recipient.address",
              "recipient.taxId",
              "recipient.iban",
            ]}
            selectGroups={selectGroups}
            placeholder={t("ui.multipleSelector.transaction.card")}
            onFilterChange={setFilter}
          ></DataTableMultiselect>
        </HorizontalFlex>
      }

      {shownDocuments?.map(({ document, highlight }) => (
        <SmallDocumentPreviewCard
          highlight={highlight}
          document={document}
          key={document.id}
          selected={document.id === selectedDocumentId}
          onClick={() => setSelectedDocumentId(document.id)}
        />
      ))}

      {shownDocuments && shownDocuments.length <= 0 && (
        <VerticalFlex
          align={"center"}
          justify={"center"}
          className={"h-20 w-full"}
        >
          <span
            className={"select-none text-sm font-semibold text-foreground/50"}
          >
            Keine passenden Dokumente vorhanden
          </span>
        </VerticalFlex>
      )}

      {selectedDocumentId !== "" && (
        <HorizontalFlex>
          <Button className={"flex-grow"} variant={"outline"} onClick={book}>
            {t("common.save")}
          </Button>
          <Button variant={"destructive"} onClick={reset}>
            <X />
          </Button>
        </HorizontalFlex>
      )}
    </VerticalFlex>
  );
};
