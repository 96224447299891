import React, { useEffect } from "react";
import { Path, UseFormReturn } from "react-hook-form";
import { InvoiceRequest } from "@/models/invoice";
import { useFormValues } from "@/util/form";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { ArrowLeftRight, CalendarSearch, Sparkles } from "lucide-react";
import { FormSimpleDate } from "@/components/form/FormSimpleDate";
import { FormLabel } from "@/components/ui/form";
import { t } from "i18next";
import { toDate, toSimpleDate } from "@/util/format";
import { SimpleDate } from "@/models/document";

const calculateDueDate = (issueDateStr: SimpleDate) => {
  const issueDate = toDate(issueDateStr);
  const dueDate = new Date(issueDate);
  dueDate.setDate(issueDate.getDate() + 14);

  if (dueDate.getMonth() !== issueDate.getMonth()) {
    dueDate.setDate(0);
  }

  return toSimpleDate(dueDate);
};

const initializeDateField = (
  form: UseFormReturn<InvoiceRequest>,
  fieldName: Path<InvoiceRequest>,
  defaultValue: SimpleDate,
) => {
  if (!form.getValues(fieldName)) {
    form.setValue(fieldName, defaultValue);
  }
};

export const GeneralInvoiceInfoFormField = ({
  form,
}: {
  form: UseFormReturn<InvoiceRequest>;
}) => {
  const formValues = useFormValues<InvoiceRequest>();

  useEffect(() => {
    const today = toSimpleDate(new Date());
    initializeDateField(form, "performancePeriodFrom", today);
    initializeDateField(form, "issueDate", today);
    initializeDateField(form, "dueDate", calculateDueDate(today));
  }, [form]);

  const [isDate, setIsDate] = React.useState<boolean>(
    !formValues.performancePeriodFrom ||
      !formValues.performancePeriodTo ||
      formValues.performancePeriodFrom === formValues.performancePeriodTo,
  );

  const setIssueDate = () => {
    form.setValue("issueDate", toSimpleDate(new Date()));
  };

  const setDueDate = () => {
    const newDueDate = calculateDueDate(formValues.issueDate);
    form.setValue("dueDate", newDueDate);
  };

  return (
    <VerticalFlex>
      <VerticalFlex gap={0}>
        <HorizontalFlex justify={"start"} align={"center"} gap={2}>
          <Label className={"w-16"}>
            {isDate ? "Leistungsdatum" : "Leistungszeitraum"}
          </Label>
          <Button
            variant={"link"}
            className={"text-muted-foreground/60 hover:text-muted-foreground"}
            onClick={(e) => {
              e.preventDefault();
              form.setValue("performancePeriodTo", null);
              setIsDate((prev) => !prev);
            }}
          >
            <ArrowLeftRight size={14} className={"ml-10 mr-2"} />
            {!isDate ? "Leistungsdatum" : "Leistungszeitraum"}
          </Button>
        </HorizontalFlex>
        {isDate ? (
          <HorizontalFlex align={"start"}>
            <FormSimpleDate
              form={form}
              name="performancePeriodFrom"
              itemClassName={"flex-1"}
            />
            <div className={"flex flex-1 items-baseline"}>
              <CalendarSearch
                className={"mx-auto h-6 w-6 text-muted-foreground/10"}
              />
            </div>
          </HorizontalFlex>
        ) : (
          <HorizontalFlex align={"start"} className={"items-baseline"}>
            <FormSimpleDate
              form={form}
              name="performancePeriodFrom"
              optional
              itemClassName={"flex-1"}
            />
            <FormLabel className={"text-muted-foreground"}>
              {t("model.document.performancePeriodTo")}
            </FormLabel>
            <FormSimpleDate
              form={form}
              name="performancePeriodTo"
              optional
              itemClassName={"flex-1"}
            />
          </HorizontalFlex>
        )}
      </VerticalFlex>

      <div className="mt-4 grid grid-cols-2 gap-4 gap-y-8">
        <VerticalFlex gap={2}>
          <Label className={"flex"}>
            Rechnungsdatum{" "}
            <Sparkles
              className={"card-shine-effect ml-1 h-4 text-muted-foreground"}
              onClick={setIssueDate}
            />
          </Label>
          <FormSimpleDate
            form={form}
            name="issueDate"
            itemClassName={"flex-1"}
          />
        </VerticalFlex>

        <VerticalFlex gap={2}>
          <Label className={"flex"}>
            Fälligkeitsdatum{" "}
            <Sparkles
              className={"card-shine-effect ml-1 h-4 text-muted-foreground"}
              onClick={setDueDate}
            />
          </Label>
          <FormSimpleDate form={form} name="dueDate" itemClassName={"flex-1"} />
        </VerticalFlex>
      </div>
    </VerticalFlex>
  );
};
