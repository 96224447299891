import { LogOut, Settings } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { useAuth } from "@/feature/auth/AuthProvider";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { UserCompanyStatus } from "@/models/userCompany";
import { useNavigate } from "react-router-dom";
import { Center } from "@/components/layout/Center";
import UploadStatusBar from "@/components/upload/UploadStatusBar";
import { HorizontalFlex } from "@/components/layout/Flex";

const HeaderNavbar = () => {
  const { user } = useLoggedIn();
  const auth = useAuth();
  const login = useLoggedIn();
  const navigation = useNavigate();
  const logout = () => {
    auth.logout();
  };

  const handleValueChange = (id: string) => {
    let company = login.identities.find((c) => c.company.id === id);
    if (company) {
      auth.switchCompany?.(company.company);
    }
  };

  return (
    <header className="sticky top-0 z-50 h-16 items-center justify-between bg-muted/60 px-16 shadow shadow-background backdrop-blur-md">
      <HorizontalFlex className={"h-16 items-center justify-between"}>
        <div id="start" className={"my-auto"}>
          <Select
            key={login.activeCompany.id}
            value={auth.userData?.activeCompany.id}
            onValueChange={(id) => handleValueChange(id)}
          >
            <SelectTrigger className="w-fit border-none hover:text-foreground/80 focus:ring-0">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {login.identities
                .filter((uc) => uc.status === UserCompanyStatus.ACTIVE)
                .map((uc) => (
                  <SelectItem value={uc.company.id} key={uc.company.id}>
                    {uc.company.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
        <HorizontalFlex id="end" justify={"center"} align={"center"} gap={8}>
          <UploadStatusBar />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Avatar className="shadow shadow-background/10 hover:cursor-pointer">
                <AvatarImage src="error" alt="@shadcn" />
                <AvatarFallback>
                  {user && user.firstname[0] + user.lastname[0]}
                </AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="flex flex-col rounded-md"
            >
              <DropdownMenuLabel className="flex self-center">
                {user.firstname + " " + user.lastname}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="flex gap-x-4"
                onClick={() => navigation("/settings/user")}
              >
                <Center className="gap-x-4">
                  <Settings className="size-4" />
                  Profil
                </Center>
              </DropdownMenuItem>
              <DropdownMenuSeparator className="mx-2" />
              <DropdownMenuItem
                className="flex cursor-pointer gap-4"
                onClick={logout}
              >
                <LogOut className="size-4" />
                Abmelden
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </HorizontalFlex>
      </HorizontalFlex>
    </header>
  );
};

export default HeaderNavbar;
