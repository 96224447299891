import { Input } from "@/components/ui/input";
import { companyApi } from "@/api/endpoints/companyApi";
import { blobUrl } from "@/api/reduxApi";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { useRef } from "react";
import { Pen } from "lucide-react";

const CompanyImageUploadForm = () => {
  const [update] = companyApi.useUpdateCompanyLogoMutation();
  const { activeCompany } = useLoggedIn();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (file: File | null) => {
    if (!file) return;
    update({ logo: file });
  };

  const handleOnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <HorizontalFlex>
      {/* Company image */}
      <VerticalFlex
        onClick={handleOnClick}
        className={
          "relative h-24 w-24 cursor-pointer overflow-hidden rounded-full transition-all duration-300 hover:scale-105"
        }
      >
        {activeCompany.logoBlob ? (
          <img
            key={activeCompany.logoBlobId}
            src={blobUrl(activeCompany.logoBlob)}
            className={"h-full w-auto object-cover"}
            alt={"logo preview"}
          />
        ) : (
          <VerticalFlex
            align={"center"}
            justify={"center"}
            className="h-24 w-24 rounded-full bg-primary"
          >
            <span className="text-muted">Kein Logo</span>
          </VerticalFlex>
        )}
        <VerticalFlex
          align={"center"}
          justify={"center"}
          title={"Bild hochladen"}
          className={
            "absolute left-0 top-0 h-full w-full opacity-0 transition-all duration-300 hover:scale-110 hover:bg-background/50 hover:opacity-100 hover:backdrop-blur-sm"
          }
        >
          <Pen />
        </VerticalFlex>
      </VerticalFlex>
      {/* Form */}
      <form>
        <Input
          ref={fileInputRef}
          type="file"
          accept="image/jpeg, image/png"
          className="hidden"
          onChange={(e) => {
            handleSubmit(e.target.files?.[0] || null);
          }}
        />
      </form>
    </HorizontalFlex>
  );
};

export default CompanyImageUploadForm;
