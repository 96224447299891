import React, { Fragment } from "react";
import { BiDocument } from "@/models/document";
import { blobUrl } from "@/api/reduxApi";
import { DownloadIcon, FileDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { FeatureFlags } from "@/models/user";

const DownloadButton = ({
  biDocument,
  className,
}: {
  biDocument: BiDocument;
  className?: string;
}) => {
  const { user } = useLoggedIn();

  const blobData = biDocument.uploadDocument?.blob;
  if (!blobData) {
    return null;
  }
  const url = blobUrl(blobData) + "?download=true";

  const ocrUrl = biDocument.uploadDocument?.ocrDataBlob
    ? blobUrl(biDocument.uploadDocument.ocrDataBlob) + "?download=true"
    : undefined;

  return (
    <Fragment>
      <a
        download={true}
        target={"_blank"}
        href={url}
        className={cn(
          className,
          "group text-muted-foreground shadow hover:bg-accent hover:text-foreground",
          "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
          "h-9 w-9",
          "group-hover:animate-bounce",
        )}
      >
        <DownloadIcon size={16} className={cn("group-hover:animate-bounce")} />
      </a>
      {(user.featureFlags || []).includes(FeatureFlags.OCRDownload) &&
        ocrUrl && (
          <a
            download={true}
            target={"_blank"}
            href={ocrUrl + "?filename="}
            className={cn(
              className,
              "opacity-50",
              "group text-muted-foreground shadow hover:bg-accent hover:text-foreground",
              "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
              "h-9 w-9",
              "group-hover:animate-bounce",
            )}
          >
            <FileDown
              size={16}
              className={cn("text-yellow-200 group-hover:animate-bounce")}
            />
          </a>
        )}
    </Fragment>
  );
};

export default DownloadButton;
