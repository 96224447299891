import { Contact, CONTACT_NO_MAX, CONTACT_NO_MIN } from "@/models/contact";

export const calculateNextUniqueCustomerNumber = (
  allContacts: Contact[],
): number => {
  const usedNums = new Set(
    allContacts.map((contact) => contact.customerNumber),
  );

  for (let maybe = CONTACT_NO_MIN; maybe <= CONTACT_NO_MAX; maybe++) {
    if (!usedNums.has(maybe)) {
      return maybe;
    }
  }

  throw new Error("No valid customer number available");
};
