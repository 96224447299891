import React from "react";

interface InvoicePreviewProps {
  preview: string;
  scale?: number;
}

const InvoicePreview = ({ preview, scale = 1 }: InvoicePreviewProps) => {
  return (
    <div className="sticky top-20 h-[50vh] w-full overflow-y-scroll rounded xl:h-[90vh]">
      <iframe
        title={"invoice-preview"}
        srcDoc={preview}
        style={{
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          width: `${210 * scale}mm`,
          height: `${297 * scale}mm`,
        }}
        className="mx-auto block overflow-y-scroll rounded border-none bg-primary shadow-md shadow-accent-foreground/30"
      />
    </div>
  );
};

export default InvoicePreview;
