import { VerticalFlex } from "@/components/layout/Flex";
import React, { useEffect } from "react";
import { Center } from "@/components/layout/Center";
import { useTranslation } from "react-i18next";
import { Banana, Handshake, RefreshCw, TriangleAlert } from "lucide-react";
import Spinner from "@/components/loading/spinner";
import { Text } from "@/components/text/Text";
import { useGoCardlesRequisitionSyncMutation } from "@/api/endpoints/bankApi";
import { Button } from "@/components/ui/button";
import { useParams } from "react-router";
import { GoCardlessRequisitionStatus } from "@/models/goCardlessRequisition";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

export const GoCardlessSyncRequisition = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [requsitionSyncMutation, requisitionSyncResult] =
    useGoCardlesRequisitionSyncMutation();

  const showWarnings = () => {
    for (let warn of requisitionSyncResult.data?.warnings || []) {
      let key = `component.gocardless.requisition.sync.${warn}`;
      if (i18n.exists(key)) {
        toast({
          title: t(key),
          icon: TriangleAlert,
        });
      }
    }
  };

  useEffect(() => {
    let handle = setTimeout(() => {
      if (id) {
        requsitionSyncMutation({ id });
      }
    }, 150);
    return () => clearTimeout(handle);
  }, [id, requsitionSyncMutation]);

  // If Sync is successfull redirect to the bank overview
  useEffect(() => {
    if (
      requisitionSyncResult.data?.requisition.status ===
      GoCardlessRequisitionStatus.LN
    ) {
      let handle = setTimeout(() => {
        navigate("/transactions");
        showWarnings();
      }, 1500);
      return () => clearTimeout(handle);
    }
  }, [requisitionSyncResult.data?.requisition, navigate]);

  let showSpinner = requisitionSyncResult.isLoading;

  const finishRequisition = (link: string | undefined) => {
    if (link) {
      window.open(link, "_self");
    }
  };

  return (
    <Center v h>
      {showSpinner && (
        <VerticalFlex align={"center"}>
          <Spinner size={50} />
          <Text>{t("component.gocardless.requisition.sync.wait")}</Text>
        </VerticalFlex>
      )}
      {!showSpinner &&
        requisitionSyncResult.data?.requisition.status ===
          GoCardlessRequisitionStatus.LN && (
          <VerticalFlex align={"center"}>
            <Handshake size={60} />
            <Text>{t("component.gocardless.requisition.sync.success")}</Text>
          </VerticalFlex>
        )}
      {!showSpinner &&
        requisitionSyncResult.data &&
        requisitionSyncResult.data?.requisition.status !==
          GoCardlessRequisitionStatus.LN && (
          <VerticalFlex align={"center"}>
            <Banana size={60} />
            <Text>{t("component.gocardless.requisition.sync.pending")}</Text>
            <Button
              onClick={() =>
                finishRequisition(requisitionSyncResult.data?.requisition.link)
              }
            >
              <RefreshCw></RefreshCw>
            </Button>
          </VerticalFlex>
        )}
    </Center>
  );
};
