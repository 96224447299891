import { reduxApi } from "@/api/reduxApi";
import { DatevValidation } from "@/models/datev";

export const exportDatevAPI = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    // check datev export
    getCheckDatevValidation: builder.query<
      DatevValidation,
      { from: string; to: string }
    >({
      query: (arg) =>
        "datev/check?from=" +
        encodeURIComponent(arg.from ?? "") +
        "&to=" +
        encodeURIComponent(arg.to ?? ""),
      providesTags: ["Document"],
    }),
  }),
});
