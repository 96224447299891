import React, { ReactNode } from "react";
import { HorizontalFlex } from "@/components/layout/Flex";
import InvoicePreview from "@/feature/invoices/read/InvoicePreview";
import { cn } from "@/lib/utils";
import { LOADING_TEMPLATE } from "@/feature/invoices/edit/form/loadingTemplate";

interface InvoiceLayoutProps {
  children: ReactNode;
  preview: string;
  showPreview?: boolean;
  className?: string;
}

export const InvoiceLayout = ({
  children,
  preview,
  showPreview = true,
  className,
}: InvoiceLayoutProps) => {
  return (
    <div
      className={cn("relative grid gap-x-8 xl:grid-cols-[1fr_auto]", className)}
    >
      <HorizontalFlex className="relative w-full">{children}</HorizontalFlex>

      {showPreview && <InvoicePreview preview={preview} />}
    </div>
  );
};

export const useInvoicePreview = (initialPreview = LOADING_TEMPLATE) => {
  const [preview, setPreview] = React.useState(initialPreview);
  return { preview, setPreview };
};

export const withInvoicePreview = (
  WrappedComponent: React.ComponentType<any>,
) => {
  return (props: any) => {
    const { preview, setPreview } = useInvoicePreview();

    return (
      <InvoiceLayout preview={preview}>
        <WrappedComponent {...props} setPreview={setPreview} />
      </InvoiceLayout>
    );
  };
};
