export const LOADING_TEMPLATE = `<!DOCTYPE html>
<html lang="de">
<head>
  <title>Rechnung wird geladen...</title>
  <style>
      @page {
          size: A4;
          margin: 30mm 0;
      }

      body {
          font-family: 'Helvetica', 'Arial', sans-serif;
          margin: 0;
          height: auto;
          font-size: 9pt;
          line-height: 1.2;
          box-sizing: border-box;
      }

      .shimmer {
          background: #f6f7f8 linear-gradient(
                  to right,
                  #f6f7f8 0%,
                  #edeef1 20%,
                  #f6f7f8 40%,
                  #f6f7f8 100%
          ) no-repeat;
          background-size: 800px 100%;
          display: inline-block;
          position: relative;
          animation: shimmer 1.5s infinite linear;
          border-radius: 4px;
      }

      @keyframes shimmer {
          0% {
              background-position: -468px 0;
          }
          100% {
              background-position: 468px 0;
          }
      }

      .heading-wrapper {
          padding: 20mm 20mm 0 20mm;
      }

      .content-wrapper {
          padding: 10mm 20mm;
      }

      /* Header area */
      .header-shimmer {
          height: 30mm;
          width: 100%;
      }

      /* Address area */
      .address-area {
          display: flex;
          justify-content: space-between;
          margin-top: 20mm;
      }

      .address-box {
          width: 85mm;
          height: 45mm;
      }

      .info-box {
          width: 60mm;
          height: 45mm;
      }

      /* Invoice table skeleton */
      .table-skeleton {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10mm;
      }

      .table-row {
          height: 20mm;
          border: 1px solid #eee;
      }

      .table-cell {
          padding: 2mm;
      }

      .cell-number {
          width: 10mm;
      }

      .cell-content {
          width: auto;
      }

      /* Summary area */
      .summary-area {
          margin-top: 10mm;
          display: flex;
          justify-content: flex-end;
      }

      .summary-box {
          width: 60mm;
          height: 40mm;
      }

      /* Footer skeleton */
      .footer {
          position: fixed;
          bottom: 0;
          width: 100%;
          height: 30mm;
          padding: 5mm 20mm;
          box-sizing: border-box;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 5mm;
      }

      .footer-item {
          height: 20mm;
      }
  </style>
</head>
<body>
<div class="heading-wrapper">
  <!-- Company header shimmer -->
  <div class="header-shimmer shimmer"></div>

  <!-- Address and info area -->
  <div class="address-area">
    <div class="address-box shimmer"></div>
    <div class="info-box shimmer"></div>
  </div>
</div>

<div class="content-wrapper">
  <!-- Invoice title shimmer -->
  <div style="height: 8mm; width: 60mm; margin-bottom: 5mm" class="shimmer"></div>

  <!-- Invoice table skeleton -->
  <table class="table-skeleton">
    <tbody>
    <tr class="table-row">
      <td class="table-cell cell-number">
        <div class="shimmer" style="height: 5mm; width: 5mm;"></div>
      </td>
      <td class="table-cell cell-content">
        <div class="shimmer" style="height: 15mm; width: 100%;"></div>
      </td>
    </tr>
    <tr class="table-row">
      <td class="table-cell cell-number">
        <div class="shimmer" style="height: 5mm; width: 5mm;"></div>
      </td>
      <td class="table-cell cell-content">
        <div class="shimmer" style="height: 15mm; width: 100%;"></div>
      </td>
    </tr>
    <tr class="table-row">
      <td class="table-cell cell-number">
        <div class="shimmer" style="height: 5mm; width: 5mm;"></div>
      </td>
      <td class="table-cell cell-content">
        <div class="shimmer" style="height: 15mm; width: 100%;"></div>
      </td>
    </tr>
    </tbody>
  </table>

  <!-- Summary area -->
  <div class="summary-area">
    <div class="summary-box shimmer"></div>
  </div>
</div>

<!-- Footer -->
<div class="footer">
  <div class="footer-item shimmer"></div>
  <div class="footer-item shimmer"></div>
  <div class="footer-item shimmer"></div>
  <div class="footer-item shimmer"></div>
</div>
</body>
</html>`;
