import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Blob } from "@/models/blob";
import { copyApiTokenToClipboard } from "@/util/clipboard";

export let GLOBAL_API_TOKEN: null | string = null;

export function blobUrl(blob: Blob) {
  return process.env.REACT_APP_API_URL + `blob/${blob.sha256}/${blob.id}`;
}

export const reduxApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || "/api/",
    prepareHeaders: (headers) => {
      if (GLOBAL_API_TOKEN) {
        headers.set("Authorization", "Bearer " + GLOBAL_API_TOKEN);
      }
      return headers;
    },
  }),
  tagTypes: ["User", "Document", "Bank", "Contact", "Invoice"],
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: () => ({}),
});

document.addEventListener("keyup", function (event) {
  event.key === "F9" && copyApiTokenToClipboard();
});

export function setGlobalApiToken(token: string) {
  GLOBAL_API_TOKEN = token;
}
