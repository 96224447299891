import React from "react";
import { BiDocument } from "@/models/document";
import { blobUrl } from "@/api/reduxApi";
import { VerticalFlex } from "@/components/layout/Flex";

const BigDocumentPreview = ({ document }: { document: BiDocument }) => {
  const blob =
    document.alternativeFormat?.blob || document.uploadDocument?.blob;

  return (
    <VerticalFlex
      className={"sticky pt-0"}
      style={{
        height: 0,
        top: "80px",
        overflow: "visible",
      }}
    >
      <div>
        {!!blob && blob.mime === "application/pdf" && (
          <iframe
            title={document.uploadDocumentId}
            className="w-full"
            style={{
              height: "calc(100vh - 96px)",
            }}
            src={blobUrl(blob)}
          />
        )}
        {!!blob && ["image/png", "image/jpeg"].includes(blob.mime) && (
          <div
            className={"ml-auto flex overflow-y-auto"}
            style={{
              height: "calc(100vh - 96px)",
            }}
          >
            <img
              className="ml-auto flex h-full w-full object-contain"
              src={blobUrl(blob)}
              alt="Document preview"
            />
          </div>
        )}
      </div>
    </VerticalFlex>
  );
};

export default BigDocumentPreview;
