import React from "react";
import { useParams } from "react-router";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import Spinner from "@/components/loading/spinner";
import { InvoiceNotFound } from "@/components/error/NotFound";
import { EditInvoiceForm } from "@/feature/invoices/edit/form/EditInvoiceForm";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import { FeatureFlags } from "@/models/user";
import WaitingForInvoices from "@/feature/invoices/WaitingForInvoices";
import {
  InvoiceLayout,
  useInvoicePreview,
} from "@/feature/invoices/edit/form/InvoiceLayout";

const EditInvoice = () => {
  const { id } = useParams();
  const { preview, setPreview } = useInvoicePreview();
  const hasInvoiceFeature = useFeatureFlag(FeatureFlags.Invoice);

  const { data, isLoading, isSuccess } = invoiceApi.useGetInvoiceQuery(
    id as string,
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!isSuccess) {
    return <InvoiceNotFound />;
  }

  if (hasInvoiceFeature) {
    return (
      <InvoiceLayout preview={preview}>
        <EditInvoiceForm
          setPreview={setPreview}
          invoiceDraft={data}
          key={"company-form"}
        />
      </InvoiceLayout>
    );
  } else {
    return <WaitingForInvoices />;
  }
};

export default EditInvoice;
