import React from "react";
import { UseFormReturn } from "react-hook-form";
import { InvoiceRequest } from "@/models/invoice";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Info } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { t } from "i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";

const AdditionalInformationCard = ({
  form,
}: {
  form: UseFormReturn<InvoiceRequest>;
}) => {
  const taxRateReasonOptions = [
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 14 UStG"}
          label={"Umsatzsteuer ausweisen"}
          description={"Steuer wird ausgewiesen"}
        />
      ),
      value: "none",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 19 UStG"}
          label={"Rechnung ohne Umsatzsteuer (Kleinunternehmerregelung)"}
          description={
            "Rechnung erfolgt nach § 19 UStG ohne Ausweis der Umsatzsteuer."
          }
        />
      ),
      value: "§ 19 UStG",
    },
    // TODO add more options
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 25c Abs. 1 Satz 2 UStG"}
          label={"Steuerbefreiung für Provisionen im Online-Shop (Goldverkauf)"}
          description={
            "Provisionen, die aus dem Verkauf von Gold über den Online-Shop resultieren, sind gemäß § 25c Abs. 1 Satz 2 UStG von der Umsatzsteuer befreit."
          }
          inActive={true}
        />
      ),
      value: "§ 25c Abs. 1 Satz 2 UStG",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 4 Nr. 5a UStG"}
          label={"Steuerbefreiung für Umsätze im Sachwertdepot"}
          description={
            "Provisionen, die aus Umsätzen im Sachwertdepot resultieren, sind gemäß § 4 Nr. 5a UStG von der Umsatzsteuer befreit."
          }
          inActive={true}
        />
      ),
      value: "§ 4 Nr. 5a UStG",
    },

    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 13b UStG"}
          label={"Reverse Charge"}
          description={
            "Steuerschuldnerschaft des Leistungsempfängers gemäß § 13b UStG."
          }
          inActive={true}
        />
      ),
      value: "§ 13b UStG",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 4 UStG"}
          label={"Leistung steuerfrei"}
          description={
            "Die Leistung ist gemäß § 4 UStG von der Umsatzsteuer befreit."
          }
          inActive={true}
        />
      ),
      value: "§ 4 UStG",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 4 Nr. 1b UStG"}
          label={"Innergemeinschaftliche Lieferung (EU steuerfrei)"}
          description={
            "Steuerfreie innergemeinschaftliche Lieferung gemäß § 4 Nr. 1b UStG."
          }
          inActive={true}
        />
      ),
      value: "§ 4 Nr. 1b UStG",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 3a Abs. 2 UStG"}
          label={"Nicht im Inland steuerbare Leistung (außerhalb EU)"}
          description={
            "Leistung ist nicht im Inland steuerbar gemäß § 3a Abs. 2 UStG."
          }
          inActive={true}
        />
      ),
      value: "§ 3a Abs. 2 UStG",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 4 Nr. 1a UStG"}
          label={"Steuerfreie Ausfuhrlieferungen"}
          description={"Steuerfreie Ausfuhrlieferung gemäß § 4 Nr. 1a UStG."}
          inActive={true}
        />
      ),
      value: "§ 4 Nr. 1a UStG",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 4 Nr. 1b UStG"}
          label={"Innergemeinschaftliche Lieferung 7%"}
          description={
            "Steuerfreie innergemeinschaftliche Lieferung mit ermäßigtem Steuersatz (7%) gemäß § 4 Nr. 1b UStG."
          }
          inActive={true}
        />
      ),
      value: "§ 4 Nr. 1b UStG7",
    },
    {
      label: (
        <ReasonSelectItem
          shortcut={"§ 4 Nr. 1b UStG"}
          label={"Innergemeinschaftliche Lieferung 19%"}
          description={
            "Steuerfreie innergemeinschaftliche Lieferung mit regulärem Steuersatz (19%) gemäß § 4 Nr. 1b UStG."
          }
          inActive={true}
        />
      ),
      value: "§ 4 Nr. 1b UStG19",
    },
  ];
  return (
    <AccordionItem value={"additional"}>
      <AccordionTrigger chevronSize={8}>
        <HorizontalFlex align={"center"} className={"text-left"}>
          <Info />
          <h4 className="text-xl">{t("component.invoices.additional")}</h4>
        </HorizontalFlex>
      </AccordionTrigger>
      <AccordionContent>
        <Card className={"backdrop-blur/60 relative rounded bg-card/90 p-8"}>
          <FormField
            control={form.control}
            name={"taxRateReason"}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Steuerbefreiung
                  <FormMessage />
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className={"h-20"}>
                      <SelectValue
                        placeholder="USt-Regelung"
                        className={"m-auto"}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <VerticalFlex
                      className={"gap-4 divide-y"}
                      justify={"center"}
                      align={"center"}
                    >
                      {taxRateReasonOptions.map((option) => (
                        <SelectItem
                          key={option.value}
                          value={option.value}
                          disabled={option.label.props.inActive}
                          className={cn(
                            option.label.props.inActive &&
                              "cursor-not-allowed text-muted-foreground hover:blur-lg",
                          )}
                        >
                          {option.label}
                        </SelectItem>
                      ))}
                    </VerticalFlex>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={`note`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Freitext
                  <FormMessage />
                </FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Zusätzliche Notizen"
                    {...field}
                    className={"max-h-48"}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </Card>
      </AccordionContent>
    </AccordionItem>
  );
};

export default AdditionalInformationCard;

const ReasonSelectItem = ({
  shortcut,
  label,
  description,
  inActive = false,
}: {
  shortcut: string;
  label: string;
  description: string;
  inActive?: boolean;
}) => {
  return (
    <span
      className={cn(
        "ml-4 flex h-20 max-w-xl flex-col text-left",
        inActive && "cursor-not-allowed text-muted-foreground hover:blur-lg",
      )}
    >
      <span className={"text-xs text-muted-foreground"}>{shortcut}</span>

      {label}
      {description && (
        <span className={"text-xs text-muted-foreground"}>{description}</span>
      )}
    </span>
  );
};
