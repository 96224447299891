import { HorizontalFlex } from "@/components/layout/Flex";
import { useTranslation } from "react-i18next";
import { BiDocumentKind } from "@/models/document";
import Spinner from "@/components/loading/spinner";
import { cn } from "@/lib/utils";

const KindBadge = ({
  kind,
  processing,
  sideways,
  className,
  small,
}: {
  kind: BiDocumentKind;
  processing: boolean;
  sideways: boolean;
  className?: string;
  small?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <HorizontalFlex
      justify={"center"}
      align={"center"}
      className={cn(
        sideways && "w-8",
        !sideways && "rounded-2xl text-xs",
        kind === BiDocumentKind.Invoice && "bg-teal-600",
        kind === BiDocumentKind.Receipt && "bg-orange-600",
        className,
      )}
    >
      {processing ? (
        <Spinner size={20} />
      ) : (
        <span
          style={
            sideways
              ? {
                  textOrientation: "sideways",
                  writingMode: "vertical-lr",
                  transform: "scale(-1)",
                  margin: 2,
                  minHeight: "4rem",
                  display: "flex",
                  justifyContent: "center",
                }
              : {}
          }
          className={cn(small && "p-1 align-middle")}
        >
          {t("model.document.kind." + kind)}
        </span>
      )}
    </HorizontalFlex>
  );
};

export default KindBadge;
