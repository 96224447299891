import { useEffect, useState } from "react";

export function useDebounce<T>(value: T, timeout: number): T {
  const [lastValue, setLastValue] = useState<T>(value);
  useEffect(() => {
    let t = setTimeout(() => {
      setLastValue(value);
    }, timeout);
    return () => clearTimeout(t);
  }, [JSON.stringify(value)]);

  return lastValue;
}
