import React from "react";
import { BiDocument } from "@/models/document";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { t } from "i18next";
import { biDocumentSchema } from "@/models/EditDocumentModel";
import { FormSimpleDate } from "@/components/form/FormSimpleDate";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { Label } from "@/components/ui/label";
import { ArrowLeftRight } from "lucide-react";

const EditInvoicePerformancePeriod = ({
  form,
  document,
  placeholderSingle,
  className,
}: {
  form: UseFormReturn<z.infer<typeof biDocumentSchema>>;
  document: BiDocument;
  placeholderSingle?: string;
  className?: string;
}) => {
  // show date or period
  const [isDate, setIsDate] = React.useState<boolean>(
    !document.performancePeriodFrom ||
      !document.performancePeriodTo ||
      document.performancePeriodFrom === document.performancePeriodTo,
  );

  return (
    <VerticalFlex className={className} gap={0}>
      <HorizontalFlex justify={"stretch"} align={"center"}>
        <Label
          className={
            "mb-[4px] mt-[6px] flex w-full cursor-pointer flex-row text-muted-foreground"
          }
          onClick={(e) => {
            e.preventDefault();
            form.setValue("performancePeriodTo", null);
            setIsDate((prev) => !prev);
          }}
        >
          {isDate
            ? t("model.document.performanceDate")
            : t("model.document.performancePeriod")}
          <ArrowLeftRight
            size={14}
            className={"ml-2 mr-2 text-muted-foreground"}
          />
          <span className={"underline hover:text-foreground"}>
            {!isDate
              ? t("model.document.performanceDate")
              : t("model.document.performancePeriod")}
          </span>
          <FlexOne />
          {!isDate && t("model.document.performancePeriodTo")}
        </Label>
      </HorizontalFlex>
      {isDate ? (
        <HorizontalFlex align={"start"}>
          <FormSimpleDate
            form={form}
            name={"performancePeriodFrom"}
            optional={true}
            className={"flex-1"}
            placeholder={placeholderSingle}
          />
        </HorizontalFlex>
      ) : (
        <HorizontalFlex align={"start"} justify={"start"} gap={2}>
          <FormSimpleDate
            form={form}
            className={"w-[60px] flex-1"}
            placeholder={t("ui.form.date")}
            name={"performancePeriodFrom"}
          />
          <FormSimpleDate
            form={form}
            className={"w-[60px] flex-1"}
            placeholder={t("ui.form.date")}
            name={"performancePeriodTo"}
          />
        </HorizontalFlex>
      )}
    </VerticalFlex>
  );
};

export default EditInvoicePerformancePeriod;
