import React from "react";
import { Check, Trash, UserCog } from "lucide-react";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import { useTranslation } from "react-i18next";
import { useToast } from "@/components/ui/use-toast";
import { Contact, ContactWithId } from "@/models/contact";
import {
  useDeleteContactMutation,
  useFilterContactsQuery,
} from "@/api/endpoints/contactsApi";
import { Button } from "@/components/ui/button";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { EditContactForm } from "@/feature/contacts/form/ContactForm";
import Spinner from "@/components/loading/spinner";

const ContactActions = ({ item }: { item: ContactWithId }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { confirmDelete } = useConfirmModal();

  const [open, setOpen] = React.useState(false);

  const { data, isLoading } = useFilterContactsQuery({
    limit: 1000,
    offset: 0,
  });
  const [deleteContact] = useDeleteContactMutation();
  if (isLoading) {
    return <Spinner />;
  }
  const deleteAction = () => {
    confirmDelete({
      text: t("component.contacts.dropdown.deleteConfirm", {
        name: item.companyName,
      }),
      onAction: async () => {
        let res = await deleteContact(item.id);
        if ("data" in res) {
          toast({
            icon: Check,
            title: t("common.success"),
            description: t("component.contacts.dropdown.deleteSuccess"),
          });
        }
      },
    });
  };
  return (
    <HorizontalFlex>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button
            size={"default"}
            variant={"ghost"}
            className={"text-muted-foreground"}
          >
            <UserCog className="mr-2 h-4 w-4" />
            {t("component.contacts.dropdown.edit")}
          </Button>
        </DialogTrigger>
        <DialogContent className={"max-w-[50vw] overflow-y-auto"}>
          <EditContactForm
            contactToUpdate={item}
            onSuccess={() => {
              setOpen(false);
            }}
            allContacts={data?.items as Contact[]}
          />
        </DialogContent>
      </Dialog>
      <Button
        size={"icon"}
        variant={"ghost"}
        onClick={deleteAction}
        className={"text-muted-foreground"}
      >
        <Trash className="h-4 w-4" />
      </Button>
    </HorizontalFlex>
  );
};

export default ContactActions;
