import React from "react";
import { MailOpen } from "lucide-react";
import { ClockLoader } from "react-spinners";

const WaitingForInvoices = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <MailOpen className="h-16 w-16 text-gray-500" />
      <h1 className="mt-4 text-4xl font-bold">Rechnungen</h1>
      <p className="mt-2 text-xl text-gray-500">Kommt bald</p>
      <ClockLoader color="#bdbdbd" />
    </div>
  );
};

export default WaitingForInvoices;
