import { useEffect, useState } from "react";

const useIsScrollingUp = () => {
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      // Check if the user is scrolling up
      if (currentScrollPos < lastScrollPos) {
        setIsScrollingUp(true);
      } else {
        setIsScrollingUp(false);
      }

      setLastScrollPos(currentScrollPos);
    };

    const optimizedScroll = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", optimizedScroll);

    return () => {
      window.removeEventListener("scroll", optimizedScroll);
    };
  }, [lastScrollPos]);

  return isScrollingUp;
};

export default useIsScrollingUp;
