import React, { ReactNode, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { t } from "i18next";
import { Building2 } from "lucide-react";
import { InvoiceCompanyUpdateSchema } from "@/models/company";
import { EditCompanyForm } from "@/feature/settings/UserSettings/form/CompanyForm";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";

interface GuardConditionCheckProps {
  triggerComponent?: ReactNode;
  children?: ReactNode;
}

const GuardConditionCheck: React.FC<GuardConditionCheckProps> = ({
  triggerComponent,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [initialValidationErrors, setInitialValidationErrors] = useState<
    Record<string, string>
  >({});
  const { activeCompany } = useLoggedIn();

  useEffect(() => {
    const parseResult = InvoiceCompanyUpdateSchema.safeParse(activeCompany);
    if (!parseResult.success) {
      const errors = parseResult.error.issues.reduce(
        (acc, issue) => {
          acc[issue.path.join(".")] = issue.message;
          return acc;
        },
        {} as Record<string, string>,
      );
      setInitialValidationErrors(errors);
      setOpen(true);
    } else {
      setInitialValidationErrors({});
    }
  }, [activeCompany]);

  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {triggerComponent || (
          <Button
            variant="outline"
            className={`fixed bottom-8 right-8 z-50 m-4 rounded-full border-accent-foreground/50 p-4 mix-blend-difference shadow-lg backdrop-blur`}
            title={t("component.invoices.editCompany")}
          >
            <Building2 className="h-6 w-6" />
            {Object.keys(initialValidationErrors).length > 0 && (
              <div
                className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white"
                title={t("component.invoices.incompleteCompanyData", {
                  fields: Object.keys(initialValidationErrors).join(", "),
                })}
              >
                !
              </div>
            )}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="max-w-[50vw] overflow-y-auto">
        <DialogHeader>{t("component.invoices.editCompany")}</DialogHeader>
        <EditCompanyForm
          schema={InvoiceCompanyUpdateSchema}
          initialValidationErrors={initialValidationErrors}
          onAbort={handleClose}
          onSave={handleClose}
        />
      </DialogContent>
      {children}
    </Dialog>
  );
};

export default GuardConditionCheck;
