import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useFilterContactsQuery } from "@/api/endpoints/contactsApi";
import { Input } from "@/components/ui/input";
import { FilterIn } from "@/api/types";
import { HorizontalFlex } from "@/components/layout/Flex";
import { ContactType, ContactWithId } from "@/models/contact";
import { InvoiceRequest } from "@/models/invoice";
import { ContactTypeComponent } from "@/feature/contacts/Contacts";
import { FormField, FormLabel, FormMessage } from "@/components/ui/form";
import { ScrollArea } from "@/components/ui/scroll-area";

const FormContactNameComboBox = ({
  form,
}: {
  form: UseFormReturn<InvoiceRequest>;
}) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [mouseEnter, setMouseEnter] = React.useState(false);

  const { data, isLoading } = useFilterContactsQuery({
    sort: "companyName",
    direction: "asc",
    ...FilterIn("companyName", search),
  });
  const value = useWatch({ name: "buyer.name" });
  const customerNumber = useWatch({ name: "buyer.customerNumber" });

  if (isLoading || data === undefined) return <div>Loading...</div>;

  const contacts = data.items as ContactWithId[];

  const handleInputChange = (e: { target: { value: string } }) => {
    setSearch(e.target.value);
    form.setValue("buyer.name", e.target.value);
  };

  const onClickItem = (item: ContactWithId) => {
    form.setValue("buyer.name", item.companyName);
    form.setValue("buyer.email", item.contactInfo?.email || "");
    form.setValue("buyer.customerNumber", item.customerNumber || 0);

    const address = item?.addresses?.[0];
    form.setValue("buyer.address.line1", address?.street || "");
    form.setValue("buyer.address.postcode", address?.postalCode || "");
    form.setValue("buyer.address.city", address?.city || "");
    form.setValue("buyer.address.countryId", address?.country || "");

    setOpen(false);
    setMouseEnter(false);
  };

  return (
    <div className="relative">
      <FormField name={"buyer.name"} render={() => <FormMessage />} />
      <FormLabel className={"text-muted-foreground"}>Name</FormLabel>
      <HorizontalFlex align={"center"} className="flex-1" gap={2}>
        <Input
          className="flex-1"
          onFocus={() => setOpen(true)}
          onBlur={() => {
            if (!mouseEnter) setOpen(false);
          }}
          onChange={handleInputChange}
          autoComplete="off"
          value={value}
        />

        {customerNumber && (
          <ContactTypeComponent
            hasLabel={false}
            type={
              contacts.find((c) => c.customerNumber === customerNumber)
                ?.type as ContactType
            }
          />
        )}
        {customerNumber && (
          <span className="text-s text-muted-foreground">{customerNumber}</span>
        )}
      </HorizontalFlex>

      {(open || mouseEnter) && contacts.length > 0 && (
        <ScrollArea
          className="absolute left-0 right-0 z-10 max-h-32 w-full overflow-y-auto rounded-b border bg-background/90 shadow-lg backdrop-blur-md"
          style={{ position: "absolute", top: "100%", zIndex: 1000 }}
          onMouseEnter={() => setMouseEnter(true)}
          onMouseLeave={() => setMouseEnter(false)}
        >
          {contacts.map((item) => (
            <HorizontalFlex
              justify="between"
              align="center"
              key={item.id}
              onClick={() => onClickItem(item)}
              className="cursor-pointer bg-background/60 px-4 py-2 transition-all duration-300 hover:bg-muted-foreground/60"
            >
              <p className="max-w-96 truncate text-sm font-medium leading-none">
                {item.companyName}
              </p>
              <div className="flex items-center gap-4">
                <p className="text-xs text-muted-foreground">
                  {item.customerNumber}
                </p>
                <ContactTypeComponent type={item.type} hasLabel={false} />
              </div>
            </HorizontalFlex>
          ))}
        </ScrollArea>
      )}
    </div>
  );
};

export default FormContactNameComboBox;
