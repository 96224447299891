import React, { useMemo } from "react";
import { Button } from "@/components/ui/button";
import { t } from "i18next";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createInvoiceRequest, CreateInvoiceRequest } from "@/models/invoice";
import { GenericComboBoxOption } from "@/components/form/GenericComboBox";
import { Mail, MailOpen } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { useNavigate } from "react-router-dom";
import { INVOICE_BT3_CODES } from "@/util/invoiceHelper";

export const CreateInvoiceComboButton = () => {
  const [create, result] = invoiceApi.useCreateInvoiceDraftMutation();
  const navigation = useNavigate();

  const options: GenericComboBoxOption[] = useMemo(() => {
    return (
      INVOICE_BT3_CODES.map((type) => ({
        label: type.germanDescription,
        description: type.code,
        value: type.code,
        disabled: type.code !== "380",
      })) || []
    );
  }, []);
  const form = useForm<CreateInvoiceRequest>({
    resolver: zodResolver(createInvoiceRequest),
    defaultValues: {
      typeCode: "380",
    },
  });

  const onSubmit = (data: CreateInvoiceRequest) => {
    create(data).then((result) => {
      if ("data" in result) {
        navigation(`/inv/${result.data.id}`);
      }
    });
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <HorizontalFlex align={"center"} gap={0}>
          <Button
            type="submit"
            className="group w-full rounded-r-none rounded-bl rounded-tl py-3 transition duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            disabled={result.isLoading}
          >
            <MailOpen size={16} className={"mr-2 inline group-hover:hidden"} />
            <Mail size={16} className={"mr-2 hidden group-hover:inline"} />
            {t("component.invoices.create.submit")}
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                type={"submit"}
                className={"rounded-l-none rounded-br rounded-tr bg-primary/95"}
              >
                {" "}
                <CaretDownIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <VerticalFlex gap={0}>
                <DropdownMenuLabel>
                  {t("component.invoices.create.selectTypePlaceholder")}
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {options.map((option) => (
                  <DropdownMenuItem
                    key={option.value}
                    disabled={option.disabled || result.isLoading}
                  >
                    <HorizontalGradientLine />
                    <span
                      className={
                        "ml-4 flex w-64 cursor-pointer flex-col text-left"
                      }
                      onClick={() => {
                        form.setValue("typeCode", option.value);
                        form.handleSubmit(onSubmit)();
                      }}
                    >
                      {option.label} {t("common.create").toLowerCase()}
                      {option.description && (
                        <span className={"text-xs text-muted-foreground"}>
                          {option.description === "380" ? "Standard: " : ""}
                          {option.description}
                        </span>
                      )}
                    </span>
                  </DropdownMenuItem>
                ))}
              </VerticalFlex>
            </DropdownMenuContent>
          </DropdownMenu>
        </HorizontalFlex>
      </form>
    </Form>
  );
};
