import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import { cn } from "@/lib/utils";
import { MemoizedReactMarkdown } from "./Markdown";
import { UserIcon, CircleIcon } from "lucide-react";
import { ChatMessageActions } from "./ChatMessageActions";
import { Chart } from "./Chart";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

export function ChatMessage({ message, ...props }) {
  let parsedContent;
  console.log("updated? user message...", message);

  try {
    parsedContent = JSON.parse(message.content)
  } catch (error) {
    parsedContent = message.content;
  }

  return (
    <div
      className={cn(
        "group relative mb-4 flex items-start md:-ml-12 transition-transform transition-opacity duration-500 ease-out",
        "animate-slideIn"
      )}
      {...props}
    >
      <div
        className={cn(
          "flex h-6 w-6 shrink-0 select-none items-center justify-center rounded-md border shadow",
          message.role === "user" ? "bg-background" : "bg-primary text-primary-foreground"
        )}
      >
        {message.role === "user" ? <UserIcon /> : <CircleIcon />}
      </div>

      <div className="flex-1 px-1 ml-4 -mt-4 space-y-2 overflow-hidden">
      <MemoizedReactMarkdown
        className="prose dark:prose-invert inline leading-normal break-words min-w-0 [word-break:break-word]"
        remarkPlugins={[remarkGfm, remarkMath]}
        components={{
          p({ children }) {
            return <p className="mb-4 mt-4 last:mb-0 leading-relaxed font-thin text-base">{children}</p>;
          },
          h2({ children }) {
            return <h2 className="mb-4 mt-6 text-lg sm:text-xl font-semibold first:mt-3">{children}</h2>;
          },
          ol({ children }) {
            return (
              <ol className="list-decimal pl-6 space-y-4 text-base marker:text-gray-400 dark:marker:text-gray-500">
                {children}
              </ol>
            );
          },
          ul({ children }) {
            return <ul className="list-disc mt-2 pl-6 space-y-2 text-sm marker:text-gray-400 dark:marker:text-gray-400">{children}</ul>;
          },
          li({ children }) {
            return (
              <li className="mb-2">
                {children}
              </li>
            );
          },
          strong({ children }) {
            return <strong className="font-semibold ">{children}</strong>;
          },
          em({ children }) {
            return <em>{children}</em>;
          },
          a({ href, children }) {
            return (
              <a
                href={href}
                className="hover:underline inline-flex items-center"
                target="_blank"
                rel="nofollow noopener"
              >
                {children}
                <ExternalLinkIcon className="ml-1 w-4 h-4" />
              </a>
            );
          },
          code({ node, inline, className, children, ...props }) {
            if (children.length) {
              if (typeof children[0] === 'string' && children[0] === "▍") {
                return <span className="mt-1 cursor-default animate-pulse">▍</span>;
              }

              if (typeof children[0] === 'string') {
                const firstChild = children[0].replace("`▍`", "▍");

                if (inline) {
                  return (
                    <code
                      className={`inline-block bg-gray-200 dark:bg-gray-800 px-1 py-0.5 rounded-md text-sm font-mono transition-all duration-200 ease-in-out hover:bg-gray-300 dark:hover:bg-gray-700 ${className}`}
                      {...props}
                    >
                      {[firstChild, ...children.slice(1)]}
                    </code>
                  );
                }
              }
            }
            return null;
          },
        }}
      >
        {message.role === "user" ? message.content : parsedContent[0]}
      </MemoizedReactMarkdown>



        {parsedContent &&
          // parsedContent[1] &&
          parsedContent[1][0] &&
          message.role === "assistant" && (
            <div className="pt-5">
              <Chart content={parsedContent[1][0]} />
            </div>
          )}

        <ChatMessageActions message={message} />
      </div>
    </div>
  );
}
