import React from "react";
import { HorizontalFlex } from "@/components/layout/Flex";
import { cn } from "@/lib/utils";
import { CoinsIcon } from "lucide-react";
import { formatMoney } from "@/util/format";
import { useGetBankTransactionQuery } from "@/api/endpoints/bankApi";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";

const SmallTransactionPreview = ({
  transactionIds,
}: {
  transactionIds: string[];
}) => {
  return (
    <ScrollArea className={"max-h-24 overflow-hidden"}>
      {transactionIds.map((transactionID) => (
        <SmallTransactionPreviewElement transactionID={transactionID} />
      ))}
    </ScrollArea>
  );
};

export default SmallTransactionPreview;

interface SmallTransactionPreviewProps {
  transactionID: string;
}

const SmallTransactionPreviewElement = ({
  transactionID,
}: SmallTransactionPreviewProps) => {
  const { data, isLoading } = useGetBankTransactionQuery(
    { id: transactionID || "" },
    { skip: !transactionID },
  );

  const negativeValue =
    data?.transactionAmount !== undefined && data.transactionAmount < 0;
  return (
    <HorizontalFlex
      className={cn(
        "select-none items-center rounded-[6px] border border-foreground/10 p-2 text-sm text-foreground",
      )}
    >
      {isLoading ? (
        <Skeleton className={"h-12"} />
      ) : (
        <HorizontalFlex gap={4} align={"center"} className={"w-full text-sm"}>
          <CoinsIcon
            className={cn(negativeValue ? "text-red-500" : "text-green-500")}
          />
          <span
            className={"line-clamp-1 font-semibold"}
            title={data?.otherPartyName}
          >
            {data?.otherPartyName}
          </span>

          <span
            className={"line-clamp-1 text-foreground/70"}
            title={data?.remittanceInformation}
          >
            {data?.remittanceInformation}
          </span>
          <span className={"font-semibold"}>
            {formatMoney(data?.transactionAmount)}
          </span>
        </HorizontalFlex>
      )}
    </HorizontalFlex>
  );
};
