import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { UseFormReturn } from "react-hook-form";
import { Contact, ContactType } from "@/models/contact";
import { t } from "i18next";
import { Button } from "@/components/ui/button";
import { Sparkles } from "lucide-react";
import { calculateNextUniqueCustomerNumber } from "@/feature/contacts/customerNumber";

const BaseInfoFormField = ({
  form,
  allContacts,
}: {
  form: UseFormReturn<Contact>;
  allContacts: Contact[];
}) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <FormField
        control={form.control}
        name="companyName"
        render={({ field }) => (
          <FormItem>
            <FormLabel className={"font-semibold"}>
              {t("component.contacts.companyName")} *
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="customerNumber"
        render={({ field }) => (
          <FormItem>
            <FormLabel className={"font-semibold"}>
              {t("component.contacts.customerNumber")} *
            </FormLabel>
            <FormControl>
              <div className="flex">
                <Input
                  {...field}
                  {...{
                    valueAsNumber: true,
                  }}
                  type="number"
                  onChange={(value) =>
                    field.onChange(value.target.valueAsNumber)
                  }
                />
                <Button
                  type={"button"}
                  size={"icon"}
                  variant={"ghost"}
                  onClick={() => {
                    form.setValue(
                      "customerNumber",
                      calculateNextUniqueCustomerNumber(allContacts),
                    );
                  }}
                >
                  <Sparkles size={18} />
                </Button>
              </div>
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.type.label")}</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue
                    placeholder={t("component.contacts.type.label")}
                  />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value={ContactType.Customer}>
                  {t("component.contacts.type.customer")}
                </SelectItem>
                <SelectItem value={ContactType.Supplier}>
                  {t("component.contacts.type.supplier")}
                </SelectItem>
                <SelectItem value={ContactType.Partner}>
                  {t("component.contacts.type.partner")}
                </SelectItem>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default BaseInfoFormField;
