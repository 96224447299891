export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function removeDuplicatesByKey<T, K>(
  arr: T[],
  key: (item: T) => K,
): T[] {
  const seen = new Set<K>(); // Set to store unique keys
  return arr.filter((item) => {
    const keyValue = key(item);
    if (seen.has(keyValue)) {
      return false; // Duplicate, exclude it
    }
    seen.add(keyValue); // Mark key as seen
    return true; // Include in the result
  });
}
