import * as React from "react";
import { t } from "i18next";
import { VerticalFlex } from "@/components/layout/Flex";

const ConfirmFinalizeInvoice = () => {
  return (
    <VerticalFlex className="text-primary">
      <span>{t("component.invoices.confirmIrreversibleAction")}</span>
      <span className={"text-muted-foreground"}>
        {t("component.invoices.explainFinalize")}
      </span>
    </VerticalFlex>
  );
};

export const ConfirmFinalizeCancelInvoice = () => {
  return (
    <VerticalFlex className="text-primary">
      <span>{t("component.invoices.confirmIrreversibleAction")}</span>
      <span className={"text-muted-foreground"}>
        {t("component.invoices.explainCancel")}
      </span>
    </VerticalFlex>
  );
};

export default ConfirmFinalizeInvoice;
