import React from "react";
import { UseFormReturn } from "react-hook-form";
import { InvoiceRequest } from "@/models/invoice";
import { Card } from "@/components/ui/card";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Package } from "lucide-react";
import LineItemsFormFields from "@/feature/invoices/edit/form/components/items/LineItemsFormFields";
import { t } from "i18next";

const ItemsAccordionItem = ({
  form,
}: {
  form: UseFormReturn<InvoiceRequest>;
}) => {
  return (
    <AccordionItem value={"items"}>
      <AccordionTrigger chevronSize={8}>
        <HorizontalFlex align={"center"} className={"text-left"}>
          <Package />
          <h4 className="text-xl">{t("component.invoices.positions")}</h4>
        </HorizontalFlex>
      </AccordionTrigger>
      <AccordionContent>
        <Card className={"backdrop-blur/60 relative rounded bg-card/90 p-8"}>
          <LineItemsFormFields form={form} />
        </Card>
      </AccordionContent>
    </AccordionItem>
  );
};

export default ItemsAccordionItem;
