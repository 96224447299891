import React from "react";
import { GeneralInvoiceInfoFormField } from "@/feature/invoices/edit/form/components/general/GeneralInvoiceInfoFormField";
import { UseFormReturn } from "react-hook-form";
import { InvoiceRequest } from "@/models/invoice";
import { Card } from "@/components/ui/card";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Calendar } from "lucide-react";
import { t } from "i18next";

const GeneralCard = ({ form }: { form: UseFormReturn<InvoiceRequest> }) => {
  return (
    <AccordionItem value={"general"}>
      <AccordionTrigger chevronSize={8}>
        <HorizontalFlex align={"center"} className={"text-left"}>
          <Calendar></Calendar>
          <h4 className={"text-xl"}>{t("component.invoices.infos")}</h4>
        </HorizontalFlex>
      </AccordionTrigger>
      <AccordionContent>
        <Card className={"backdrop-blur/60 relative rounded bg-card/90 p-8"}>
          <GeneralInvoiceInfoFormField form={form} />
        </Card>
      </AccordionContent>
    </AccordionItem>
  );
};

export default GeneralCard;
