/* eslint-disable max-lines */
"use client";

import React, { type FC, useEffect, useRef, useState } from "react";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Calendar } from "./calendar";
import { DateInput } from "./date-input";
import { Label } from "./label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import { Switch } from "./switch";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { translation } from "@/i18n/translation";

const PRESETS_DEFAULT = [
  [
    "today",
    (from: Date, to: Date) => {
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "yesterday",
    (from: Date, to: Date) => {
      from.setDate(from.getDate() - 1);
      from.setHours(0, 0, 0, 0);
      to.setDate(to.getDate() - 1);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "last7",
    (from: Date, to: Date) => {
      from.setDate(from.getDate() - 6);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "last14",
    (from: Date, to: Date) => {
      from.setDate(from.getDate() - 13);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "last30",
    (from: Date, to: Date) => {
      from.setDate(from.getDate() - 29);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "thisWeek",
    (from: Date, to: Date) => {
      const first = from.getDate() - from.getDay();
      from.setDate(first);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "lastWeek",
    (from: Date, to: Date) => {
      from.setDate(from.getDate() - 7 - from.getDay());
      to.setDate(to.getDate() - to.getDay() - 1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "thisMonth",
    (from: Date, to: Date) => {
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
    },
  ],
  [
    "lastMonth",
    (from: Date, to: Date) => {
      from.setMonth(from.getMonth() - 1);
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setDate(0);
      to.setHours(23, 59, 59, 999);
    },
  ],
] as [string, (from: Date, to: Date) => void][];

const PRESETS_MONTHS = [
  [
    "thisMonth",
    (from: Date, to: Date) => {
      from.setDate(1);
      from.setMonth(from.getMonth());
      to.setMonth(from.getMonth() + 1);
      to.setDate(0);
    },
  ],
  [
    "lastMonth",
    (from: Date, to: Date) => {
      from.setDate(1);
      from.setMonth(from.getMonth() - 1);
      to.setMonth(from.getMonth() + 1);
      to.setDate(0);
    },
  ],
  [
    "last3Months",
    (from: Date, to: Date) => {
      from.setDate(1);
      from.setMonth(from.getMonth() - 2);
      to.setMonth(to.getMonth() + 1);
      to.setDate(0);
    },
  ],
  [
    "last6Months",
    (from: Date, to: Date) => {
      from.setDate(1);
      from.setMonth(from.getMonth() - 5);
      to.setMonth(to.getMonth() + 1);
      to.setDate(0);
    },
  ],
  [
    "last12Months",
    (from: Date, to: Date) => {
      from.setDate(1);
      from.setMonth(from.getMonth() - 11);
      to.setMonth(to.getMonth() + 1);
      to.setDate(0);
    },
  ],
] as [string, (from: Date, to: Date) => void][];

const PRESETS = {
  month: PRESETS_MONTHS,
  default: PRESETS_DEFAULT,
};

export interface DateRangePickerProps {
  /** Click handler for applying the updates from DateRangePicker. */
  onUpdate?: (values: { range?: DateRange; rangeCompare?: DateRange }) => void;
  /** Initial value for start date */
  initialDateFrom?: Date | string;
  /** Initial value for end date */
  initialDateTo?: Date | string;
  /** Initial Selected */
  initialSelected?: boolean;
  /** Initial value for start date for compare */
  initialCompareFrom?: Date | string;
  /** Initial value for end date for compare */
  initialCompareTo?: Date | string;
  /** Alignment of popover */
  align?: "start" | "center" | "end";
  /** Option for locale */
  locale?: string;
  /** Option for showing compare feature */
  showCompare?: boolean;
  //
  value?: [Date, Date];
  //
  preset?: keyof typeof PRESETS;
}

const formatDate = (date: Date, locale: string = "de-DE"): string => {
  return date.toLocaleDateString(locale, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const getDateAdjustedForTimezone = (dateInput: Date | string): Date => {
  if (typeof dateInput === "string") {
    // Split the date string to get year, month, and day parts
    const parts = dateInput.split("-").map((part) => parseInt(part, 10));
    // Create a new Date object using the local timezone
    // Note: Month is 0-indexed, so subtract 1 from the month part
    return new Date(parts[0], parts[1] - 1, parts[2]);
  } else {
    // If dateInput is already a Date object, return it directly
    return dateInput;
  }
};

interface DateRange {
  from: Date;
  to: Date | undefined;
}

/** The DateRangePicker component allows a user to select a range of dates */
export const DateRangePicker: FC<DateRangePickerProps> & {
  filePath: string;
} = ({
  initialDateFrom = (() => {
    let date = new Date();
    date.setDate(date.getDate() - 29);
    date.setHours(0, 0, 0, 0);
    return date;
  })(),
  initialDateTo = (() => {
    let date = new Date();
    date.setHours(23, 59, 59);
    return date;
  })(),
  initialSelected = false,
  initialCompareFrom,
  initialCompareTo,
  onUpdate,
  align = "end",
  locale = "de-DE",
  showCompare = true,
  preset = "default",
  value,
}): JSX.Element => {
  const { t } = useTranslation();

  // Define presets
  const propPreset = PRESETS[preset].map(([name, fn]) => ({
    name,
    fn,
    label: translation.t("ui.dateRangePicker." + name),
  }));
  const [isOpen, setIsOpen] = useState(false);

  const [dateSelected, setDateSelected] = useState(initialSelected);

  const [range, setRange] = useState<DateRange>({
    from: getDateAdjustedForTimezone(initialDateFrom),
    to: initialDateTo
      ? getDateAdjustedForTimezone(initialDateTo)
      : getDateAdjustedForTimezone(initialDateFrom),
  });

  useEffect(() => {
    if (value) {
      setRange({
        from: getDateAdjustedForTimezone(value[0]),
        to: getDateAdjustedForTimezone(value[1]),
      });
      setDateSelected(true);
    }
  }, [JSON.stringify(value)]);

  const [rangeCompare, setRangeCompare] = useState<DateRange | undefined>(
    initialCompareFrom
      ? {
          from: new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
          to: initialCompareTo
            ? new Date(new Date(initialCompareTo).setHours(0, 0, 0, 0))
            : new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
        }
      : undefined,
  );

  // Refs to store the values of range and rangeCompare when the date picker is opened
  const openedRangeRef = useRef<DateRange | undefined>();
  const openedRangeCompareRef = useRef<DateRange | undefined>();

  const [selectedPreset, setSelectedPreset] = useState<string | undefined>(
    undefined,
  );

  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== "undefined" ? window.innerWidth < 960 : false,
  );

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 960);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getPresetRange = (presetName: string): DateRange => {
    const preset = propPreset.find(({ name }) => name === presetName);
    if (!preset) throw new Error(`Unknown date range preset: ${presetName}`);
    const from = new Date();
    const to = new Date();

    preset.fn(from, to);

    return { from, to };
  };

  const setPreset = (preset: string): void => {
    const range = getPresetRange(preset);
    setRange(range);
    if (rangeCompare) {
      const rangeCompare = {
        from: new Date(
          range.from.getFullYear() - 1,
          range.from.getMonth(),
          range.from.getDate(),
        ),
        to: range.to
          ? new Date(
              range.to.getFullYear() - 1,
              range.to.getMonth(),
              range.to.getDate(),
            )
          : undefined,
      };
      setRangeCompare(rangeCompare);
    }
  };

  const checkPreset = (): void => {
    for (const preset of propPreset) {
      const presetRange = getPresetRange(preset.name);

      const normalizedRangeFrom = new Date(range.from);
      normalizedRangeFrom.setHours(0, 0, 0, 0);
      const normalizedPresetFrom = new Date(
        presetRange.from.setHours(0, 0, 0, 0),
      );

      const normalizedRangeTo = new Date(range.to ?? 0);
      normalizedRangeTo.setHours(0, 0, 0, 0);
      const normalizedPresetTo = new Date(
        presetRange.to?.setHours(0, 0, 0, 0) ?? 0,
      );

      if (
        normalizedRangeFrom.getTime() === normalizedPresetFrom.getTime() &&
        normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
      ) {
        setSelectedPreset(preset.name);
        return;
      }
    }

    setSelectedPreset(undefined);
  };

  const from =
    typeof initialDateFrom === "string"
      ? getDateAdjustedForTimezone(initialDateFrom)
      : initialDateFrom;

  const to = initialDateTo
    ? typeof initialDateTo === "string"
      ? getDateAdjustedForTimezone(initialDateTo)
      : initialDateTo
    : from;

  const resetValues = (): void => {
    let newRange = {
      from,
      to,
    };
    let newDateSelected = initialSelected;
    let newCompareRange = initialCompareFrom
      ? {
          from:
            typeof initialCompareFrom === "string"
              ? getDateAdjustedForTimezone(initialCompareFrom)
              : initialCompareFrom,
          to: initialCompareTo
            ? typeof initialCompareTo === "string"
              ? getDateAdjustedForTimezone(initialCompareTo)
              : initialCompareTo
            : typeof initialCompareFrom === "string"
              ? getDateAdjustedForTimezone(initialCompareFrom)
              : initialCompareFrom,
        }
      : undefined;

    //

    setRange(newRange);
    setDateSelected(newDateSelected);
    setRangeCompare(newCompareRange);

    //
    if (newDateSelected) {
      onUpdate?.({ range: newRange, rangeCompare: newCompareRange });
    } else {
      onUpdate?.({});
    }
  };

  useEffect(() => {
    checkPreset();
  }, [range]);

  const PresetButton = ({
    preset,
    label,
    isSelected,
  }: {
    preset: string;
    label: string;
    isSelected: boolean;
  }): JSX.Element => (
    <Button
      className={cn(dateSelected && isSelected && "pointer-events-none")}
      variant="ghost"
      onClick={() => {
        setPreset(preset);
        setDateSelected(true);
      }}
    >
      <>
        <span
          className={cn(
            "pr-2 opacity-0",
            dateSelected && isSelected && "opacity-70",
          )}
        >
          <CheckIcon width={18} height={18} />
        </span>
        {label}
      </>
    </Button>
  );

  // Helper function to check if two date ranges are equal
  const areRangesEqual = (a?: DateRange, b?: DateRange): boolean => {
    if (!a || !b) return a === b; // If either is undefined, return true if both are undefined
    return (
      a.from.getTime() === b.from.getTime() &&
      (!a.to || !b.to || a.to.getTime() === b.to.getTime())
    );
  };

  useEffect(() => {
    if (isOpen) {
      openedRangeRef.current = range;
      openedRangeCompareRef.current = rangeCompare;
    }
  }, [isOpen]);

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(open: boolean) => {
        if (!open) {
          resetValues();
        }
        setIsOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          size={"lg"}
          variant="outline"
          className="border-none shadow shadow-background"
        >
          <div className="text-right">
            <div className="py-1">
              {dateSelected ? (
                <div
                  className={"w-48 text-center"}
                >{`${formatDate(range.from, locale)}${
                  range.to != null ? " - " + formatDate(range.to, locale) : ""
                }`}</div>
              ) : (
                <div className={"flex w-48 justify-around text-center"}>
                  <span className={"opacity-50"}>
                    {t("ui.dateRangePicker.from")}
                  </span>
                  <span>{"-"}</span>
                  <span className={"opacity-50"}>
                    {t("ui.dateRangePicker.to")}
                  </span>
                </div>
              )}
            </div>
            {rangeCompare != null && (
              <div className="-mt-1 text-xs opacity-60">
                <>
                  vs. {formatDate(rangeCompare.from, locale)}
                  {rangeCompare.to != null
                    ? ` - ${formatDate(rangeCompare.to, locale)}`
                    : ""}
                </>
              </div>
            )}
          </div>
          <div className="-mr-2 scale-125 pl-1 opacity-60">
            {
              <ChevronDownIcon
                width={24}
                className={cn("transition-all", isOpen && "rotate-180")}
              />
            }
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent align={align} className="w-auto rounded-md">
        <div className="flex py-2">
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex flex-col items-center justify-end gap-2 px-3 pb-4 lg:flex-row lg:items-start lg:pb-0">
                {showCompare && (
                  <div className="flex items-center space-x-2 py-1 pr-4">
                    <Switch
                      defaultChecked={Boolean(rangeCompare)}
                      onCheckedChange={(checked: boolean) => {
                        if (checked) {
                          if (!range.to) {
                            setRange({
                              from: range.from,
                              to: range.from,
                            });
                          }
                          setRangeCompare({
                            from: new Date(
                              range.from.getFullYear(),
                              range.from.getMonth(),
                              range.from.getDate() - 365,
                            ),
                            to: range.to
                              ? new Date(
                                  range.to.getFullYear() - 1,
                                  range.to.getMonth(),
                                  range.to.getDate(),
                                )
                              : new Date(
                                  range.from.getFullYear() - 1,
                                  range.from.getMonth(),
                                  range.from.getDate(),
                                ),
                          });
                        } else {
                          setRangeCompare(undefined);
                        }
                      }}
                      id="compare-mode"
                    />
                    <Label htmlFor="compare-mode">Compare</Label>
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <DateInput
                      displayNone={!dateSelected}
                      value={range.from}
                      onChange={(date) => {
                        const toDate =
                          range.to == null || date > range.to ? date : range.to;
                        setDateSelected(true);
                        setRange((prevRange) => ({
                          ...prevRange,
                          from: date,
                          to: toDate,
                        }));
                      }}
                    />
                    <div className="py-1">-</div>
                    <DateInput
                      displayNone={!dateSelected}
                      value={dateSelected ? range.to : undefined}
                      onChange={(date) => {
                        const fromDate = date < range.from ? date : range.from;
                        setDateSelected(true);
                        setRange((prevRange) => ({
                          ...prevRange,
                          from: fromDate,
                          to: date,
                        }));
                      }}
                    />
                  </div>
                  {rangeCompare != null && (
                    <div className="flex gap-2">
                      <DateInput
                        value={rangeCompare?.from}
                        onChange={(date) => {
                          if (rangeCompare) {
                            const compareToDate =
                              rangeCompare.to == null || date > rangeCompare.to
                                ? date
                                : rangeCompare.to;
                            setRangeCompare((prevRangeCompare) => ({
                              ...prevRangeCompare,
                              from: date,
                              to: compareToDate,
                            }));
                          } else {
                            setRangeCompare({
                              from: date,
                              to: new Date(),
                            });
                          }
                        }}
                      />
                      <div className="py-1">-</div>
                      <DateInput
                        value={rangeCompare?.to}
                        onChange={(date) => {
                          if (rangeCompare && rangeCompare.from) {
                            const compareFromDate =
                              date < rangeCompare.from
                                ? date
                                : rangeCompare.from;
                            setRangeCompare({
                              ...rangeCompare,
                              from: compareFromDate,
                              to: date,
                            });
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {isSmallScreen && (
                <Select
                  defaultValue={selectedPreset}
                  onValueChange={(value) => {
                    setPreset(value);
                  }}
                >
                  <SelectTrigger className="mx-auto mb-2 w-[180px]">
                    <SelectValue placeholder="Select..." />
                  </SelectTrigger>
                  <SelectContent>
                    {propPreset.map((preset) => (
                      <SelectItem key={preset.name} value={preset.name}>
                        {preset.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
              <div>
                <Calendar
                  mode="range"
                  onSelect={(value: { from?: Date; to?: Date } | undefined) => {
                    if (value?.from != null) {
                      setRange({ from: value.from, to: value?.to });
                      setDateSelected(true);
                    }
                  }}
                  selected={dateSelected ? range : undefined}
                  numberOfMonths={isSmallScreen ? 1 : 2}
                  defaultMonth={
                    new Date(
                      new Date().setMonth(
                        new Date().getMonth() - (isSmallScreen ? 0 : 1),
                      ),
                    )
                  }
                />
              </div>
            </div>
          </div>
          {!isSmallScreen && (
            <div className="flex flex-col items-end gap-1 pb-6 pl-6 pr-2">
              <div className="flex w-full flex-col items-end gap-1 pb-6 pl-6 pr-2">
                {propPreset.map((preset) => (
                  <PresetButton
                    key={preset.name}
                    preset={preset.name}
                    label={preset.label}
                    isSelected={selectedPreset === preset.name}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2 py-2 pr-4">
          <Button
            onClick={() => {
              setIsOpen(false);
              resetValues();
            }}
            variant="ghost"
          >
            {t("ui.dateRangePicker.reset")}
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
              if (
                !areRangesEqual(range, openedRangeRef.current) ||
                !areRangesEqual(rangeCompare, openedRangeCompareRef.current)
              ) {
                if (dateSelected) {
                  onUpdate?.({ range, rangeCompare });
                } else {
                  onUpdate?.({});
                }
              }
            }}
          >
            {t("ui.dateRangePicker.apply")}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

DateRangePicker.displayName = "DateRangePicker";
DateRangePicker.filePath =
  "libs/shared/ui-kit/src/lib/date-range-picker/date-range-picker.tsx";
