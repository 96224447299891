import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormField as RHFFormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import {
  Control,
  ControllerRenderProps,
  FieldValues,
  Path,
} from "react-hook-form";
import { formatNumberFancy, parseNumber, parseQuantity } from "@/util/format";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Euro, Percent } from "lucide-react";

interface FormFieldProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label?: string;
  type?:
    | "text"
    | "number"
    | "percent"
    | "euro"
    | "string"
    | "email"
    | "quantity";
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  [x: string]: unknown; // For additional props
}

export const FormField = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  type = "text",
  defaultValue,
  placeholder,
  className,
  ...rest
}: FormFieldProps<TFieldValues>) => {
  return (
    <RHFFormField
      control={control}
      name={name}
      render={({ field }) => (
        <InnerField<TFieldValues>
          field={field}
          label={label}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={className}
          rest={rest}
        />
      )}
    />
  );
};

interface InnerFieldProps<TFieldValues extends FieldValues> {
  field: ControllerRenderProps<TFieldValues, Path<TFieldValues>>;
  label?: string;
  type?:
    | "text"
    | "number"
    | "percent"
    | "euro"
    | "string"
    | "email"
    | "quantity";
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  rest?: Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "name" | "value" | "onChange" | "onBlur" | "ref"
  >;
}

const InnerField = <TFieldValues extends FieldValues>({
  field,
  label,
  type,
  placeholder,
  className,
  defaultValue,
  rest,
}: InnerFieldProps<TFieldValues>) => {
  const [displayValue, setDisplayValue] = useState<string>(defaultValue || "");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } },
  ) => {
    const value = e.target.value;

    if (type === "euro" || type === "percent") {
      setDisplayValue(value);
      const numericValue = parseNumber(value);
      field.onChange(numericValue);
    } else if (type === "quantity") {
      setDisplayValue(value);
      const numericValue = parseQuantity(value);
      field.onChange(numericValue);
    } else {
      setDisplayValue(value);
      field.onChange(value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange({ target: { value: defaultValue } });
    }
  }, []);

  const handleBlur = () => {
    if ((type === "euro" || type === "percent") && field.value) {
      setDisplayValue(formatNumberFancy(field.value as number));
    }
    field.onBlur();
  };

  return (
    <FormItem>
      {label && (
        <FormLabel className="flex items-center gap-1 text-muted-foreground">
          {label}
        </FormLabel>
      )}
      <FormControl>
        <HorizontalFlex align={"center"} gap={2}>
          <Input
            placeholder={placeholder}
            defaultValue={defaultValue}
            type={type === "euro" ? "text" : type}
            {...field}
            value={displayValue}
            className={cn(className)}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={field.ref}
            {...rest}
          />
          {type === "euro" && <Euro />}
          {type === "percent" && <Percent />}
        </HorizontalFlex>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};
