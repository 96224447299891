import { Button } from "@/components/ui/button";
import { PromptForm } from "./PromptForm";
import { ButtonScrollToBottom } from "./ButtonScrollToButton";
import { RefreshCwIcon, StopCircleIcon } from "lucide-react";
import React from "react";

export function ChatPanel({
  id,
  isLoading,
  stop,
  append,
  reload,
  input,
  setInput,
  messages,
}) {
  return (
    <div className="absolute -bottom-12 left-1/2 -translate-x-1/2 transform from-muted/10 from-10% to-muted/30 to-50% sm:min-w-[500px] lg:min-w-[700px]">
      <ButtonScrollToBottom />
      <div className="sm:max-w-3xl sm:px-4">
        {isLoading && (
          <div className="mb-5 flex w-full flex-col items-center space-y-2 pb-2">
            <span className="text-cacao-dark text-sm">Analysiere Daten...</span>
          </div>
        )}
        <div className="flex h-10 items-center justify-center">
          {isLoading ? (
            <Button
              variant="outline"
              onClick={() => stop()}
              className="bg-background"
            >
              <StopCircleIcon className="mr-2" />
              Abbrechen
            </Button>
          ) : (
            messages?.length > 0 && (
              <Button
                variant="outline"
                onClick={() => reload()}
                className="bg-background"
              >
                <RefreshCwIcon className="mr-2" />
                Neu generieren
              </Button>
            )
          )}
        </div>
        <div
          className={
            isLoading
              ? "bg-cacao animate-pulseGlow space-y-4 border-t px-4 py-2 shadow-lg sm:rounded-t-xl sm:border md:py-4"
              : "bg-cacao items-center space-y-4 border-t px-4 py-2 shadow-lg sm:rounded-t-xl sm:border md:py-4"
          }
        >
          <PromptForm
            onSubmit={async (value) => {
              await append({
                id,
                content: value,
                role: "user",
              });
            }}
            input={input}
            setInput={setInput}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
