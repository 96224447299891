import React from "react";
import { UseFormReturn } from "react-hook-form";
import { AddressFormFields } from "./AddressFormFields";
import { InvoiceRequest } from "@/models/invoice";
import FormContactNameComboBox from "@/feature/invoices/edit/form/components/buyer/FormContactNameComboBox";
import { VerticalFlex } from "@/components/layout/Flex";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { FormField } from "@/components/ui/form";

interface BuyerInfoFormFieldsProps {
  form: UseFormReturn<InvoiceRequest>;
}

export const BuyerInfoFormFields = ({ form }: BuyerInfoFormFieldsProps) => (
  <VerticalFlex
    gap={8}
    className={"backdrop-blur/60 relative h-full rounded bg-card/90"}
  >
    <div className="grid grid-cols-2 gap-4 rounded">
      <FormContactNameComboBox form={form} />
      <FormSimpleText form={form} name={`buyer.email`} label={"Mail"} />
    </div>
    <FormField name={"buyer.customerNumber"} render={() => <></>} />
    <AddressFormFields form={form} prefix="buyer." />
  </VerticalFlex>
);
