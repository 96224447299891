import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "@/components/ui/form";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { Button } from "@/components/ui/button";
import { FlexOne } from "@/components/layout/Flex";
import { DialogClose } from "@/components/ui/dialog";
import {
  Contact,
  ContactInfoSchema,
  ContactType,
  ContactWithId,
} from "@/models/contact";
import PaymentFormFields from "@/feature/contacts/form/optional/PaymentFormFields";
import ContactInfoFormFields from "@/feature/contacts/form/optional/ContactInfoFormFields";
import BaseInfoFormField from "@/feature/contacts/form/optional/BaseInfoFormField";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import AddressFormFields from "@/feature/contacts/form/optional/AddressFormFields";
import React, { useEffect } from "react";
import {
  useCreateContactMutation,
  useUpdateContactMutation,
} from "@/api/endpoints/contactsApi";
import { useToast } from "@/components/ui/use-toast";
import { OctagonAlert, UserCheck } from "lucide-react";
import { ReloadIcon } from "@radix-ui/react-icons";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { isFetchBaseQueryError } from "@/util/error";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Title } from "@/components/text/Title";
import { t } from "i18next";
import { cn } from "@/lib/utils";
import { calculateNextUniqueCustomerNumber } from "@/feature/contacts/customerNumber";

export const EditContactForm = ({
  contactToUpdate,
  onSuccess,
  allContacts,
}: {
  contactToUpdate: ContactWithId;
  onSuccess: () => void;
  allContacts: Contact[];
}) => {
  const [update, result] = useUpdateContactMutation();

  return (
    <ContactForm
      handleSubmit={(data) => {
        return update({ id: contactToUpdate.id, body: data });
      }}
      defaultValues={contactToUpdate}
      result={result}
      onSuccess={onSuccess}
      allContacts={allContacts}
      title={t("component.contacts.modal.titleEdit")}
    />
  );
};

export const CreateContactForm = ({
  onSuccess,
  allContacts,
}: {
  onSuccess: () => void;
  allContacts: Contact[];
}) => {
  const [create, result] = useCreateContactMutation();
  const defaultValue: Contact = {
    customerNumber: calculateNextUniqueCustomerNumber(allContacts),
    companyName: "",
    type: ContactType.Customer,
    addresses: [{ city: "", country: "DE", postalCode: "", street: "" }],
  };
  return (
    <ContactForm
      handleSubmit={(data) => {
        return create(data);
      }}
      defaultValues={defaultValue}
      result={result}
      onSuccess={onSuccess}
      allContacts={allContacts}
      title={t("component.contacts.modal.titleAdd")}
    />
  );
};

function ContactForm({
  handleSubmit,
  defaultValues,
  result,
  onSuccess,
  allContacts,
  title,
}: {
  title: string;
  handleSubmit: (
    data: Contact,
  ) => Promise<
    { data: Contact } | { error: FetchBaseQueryError | SerializedError }
  >;
  defaultValues: Contact;
  result: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    reset: () => void;
  };
  onSuccess: () => void;
  allContacts: Contact[];
}) {
  const { t } = useTranslation();
  const form = useForm<Contact>({
    resolver: zodResolver(ContactInfoSchema),
    defaultValues: defaultValues,
  });

  const [showLoading, setShowLoading] = React.useState(false);
  useEffect(() => {
    if (result.isLoading) {
      setShowLoading(true);
    } else {
      let timeout = setTimeout(() => {
        setShowLoading(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [result.isLoading]);

  const { toast } = useToast();
  const {
    formState: { errors },
  } = form;
  const hasAddressErrors =
    Array.isArray(errors.addresses) &&
    errors.addresses.some((error) => error?.root);

  const [activeTab, setActiveTab] = React.useState<
    string | "contact" | "pay" | "address"
  >("contact");

  useEffect(() => {
    if (hasAddressErrors) {
      setActiveTab("address");
    }
  }, [hasAddressErrors]);
  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          form.handleSubmit(
            async (data) => {
              handleSubmit(data).then((result) => {
                if ("error" in result) {
                  const error = result.error;
                  if (isFetchBaseQueryError(error) && error.status === 409) {
                    // aufruf GetNextCustomerNumber
                    form.setError("customerNumber", {
                      type: "manual",
                      // CustomerNumber Vorschlagen
                      // Button mit AI Vorschlag Smiley, wenn man den drückt, dann wird der Value der CustomerNumber auf den GetNextCustomerNumber-Value gesetzt
                      message: t("component.contacts.invalidCustomerNumber"),
                    });
                  }
                } else {
                  // Success
                  toast({
                    icon: UserCheck,
                    title: t("common.success"),
                    description: t("component.contacts.modal.success"),
                  });
                  form.reset();
                  onSuccess();
                }
              });
            },
            (errors) => {
              console.error("contact_err", errors);
            },
          )(e);
        }}
        onError={(errors) => console.log(errors)}
        className={"flex flex-col gap-4"}
      >
        <Title>{title}</Title>
        <HorizontalGradientLine />
        <BaseInfoFormField form={form} allContacts={allContacts} />
        <Tabs
          className={"mt-16"}
          defaultValue={"contact"}
          value={activeTab}
          onValueChange={setActiveTab}
        >
          <TabsList>
            <TabsTrigger value={"contact"}>
              {t("component.contacts.contactInfo")}
            </TabsTrigger>
            <TabsTrigger value={"pay"}>
              {t("component.contacts.paymentInfo.label")}
            </TabsTrigger>
            <TabsTrigger
              value={"address"}
              className={cn(
                hasAddressErrors
                  ? "border border-destructive bg-destructive/20 text-foreground"
                  : "",
              )}
            >
              {hasAddressErrors && (
                <OctagonAlert size={14} className={"mr-2 text-destructive"} />
              )}
              {t("component.contacts.addresses.label")}
            </TabsTrigger>
          </TabsList>
          <TabsContent value={"contact"}>
            <ScrollArea className={"flex h-72 flex-col gap-4"}>
              <ContactInfoFormFields form={form} />
            </ScrollArea>
          </TabsContent>
          <TabsContent value={"pay"}>
            <ScrollArea className={"flex h-72 flex-col gap-4"}>
              <PaymentFormFields form={form} />
            </ScrollArea>
          </TabsContent>
          <TabsContent value={"address"}>
            <ScrollArea className={"flex h-72 flex-col gap-4"}>
              <AddressFormFields form={form} />
            </ScrollArea>
          </TabsContent>
        </Tabs>

        <HorizontalGradientLine />
        <div className="grid grid-cols-4 gap-4">
          <FlexOne />
          <FlexOne />
          <DialogClose asChild>
            <Button type="reset" variant={"ghost"}>
              {t("component.contacts.cancel")}
            </Button>
          </DialogClose>
          <Button type="submit" disabled={showLoading}>
            {showLoading && (
              <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
            )}
            {t("component.contacts.submit")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
