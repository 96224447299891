import React from "react";
import { BiDocument } from "@/models/document";
import DeleteButton from "@/feature/documents/components/Card/CardHeader/DeleteButton";
import EditButton from "@/feature/documents/components/Card/CardHeader/EditButton";
import DownloadButton from "@/feature/documents/components/Card/CardHeader/DownloadButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Check, EllipsisVerticalIcon } from "lucide-react";
import { cn } from "@/lib/utils";

import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import { t } from "i18next";
import { matchingApi } from "@/api/endpoints/matchingApi";
import { toast } from "@/components/ui/use-toast";
import SmallTransactionPreview from "@/feature/documents/components/Card/CardBody/SmallTransactionPreview";

function DocumentActions({
  biDocument,
  asDropdown = false,
  className,
}: {
  biDocument: BiDocument;
  asDropdown?: boolean;
  className?: string;
}) {
  const { mode, setMode } = useDocumentContext();
  const isBooked = !!biDocument.match;
  const { confirmDelete } = useConfirmModal();
  const [deleteTransaction] = matchingApi.useDeleteMatchMutation();

  const confirmUnlink = () =>
    confirmDelete({
      text: (
        <div>
          {t("model.document.isBooked")}
          {biDocument.match?.bankTransactions && (
            <SmallTransactionPreview
              transactionIds={biDocument.match?.bankTransactions.map(
                (b) => b.bankTransactionId,
              )}
            />
          )}
        </div>
      ),
      onAction: async () => {
        if (biDocument.match) {
          deleteTransaction(biDocument.match).then((result) => {
            if ("data" in result) {
              toast({
                title: t("common.successDelete"),
                icon: Check,
              });
            }
          });
        }
      },
    });

  const buttons = (
    <>
      <DeleteButton
        document={biDocument}
        beforeDelete={() => {
          if (isBooked) {
            confirmUnlink();
            return false;
          }
          return true;
        }}
      />
      <DownloadButton biDocument={biDocument} />
      <EditButton
        isEditing={mode === "edit"}
        onClick={() => {
          const prevMode = mode;
          if (prevMode === "edit") {
            setMode("small");
            return;
          }
          if (
            prevMode === "small" ||
            prevMode === "preview" ||
            prevMode === "edit-saved"
          ) {
            setMode("edit");
            return;
          }
        }}
      />
    </>
  );

  return (
    <div className={cn(className)}>
      {asDropdown ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size={"icon"} variant="ghost" className={"group"}>
              <EllipsisVerticalIcon
                className={
                  "text-muted-foreground transition-all group-hover:rotate-90 group-hover:text-foreground"
                }
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>{buttons}</DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <>{buttons}</>
      )}
    </div>
  );
}

export default DocumentActions;
