import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ReloadIcon } from "@radix-ui/react-icons";
import { CompanyUpdateData, CompanyUpdateSchema } from "@/models/company";
import { companyApi } from "@/api/endpoints/companyApi";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { useToast } from "@/components/ui/use-toast";
import GeneralInfoFormField from "@/feature/settings/UserSettings/form/optional/GeneralInfoFormField";
import CompanyInfoFormFields from "@/feature/settings/UserSettings/form/optional/CompanyInfoFormFields";
import AccountingFormFields from "@/feature/settings/UserSettings/form/optional/AccountingFormFields";
import BankDetailsFormFields from "@/feature/settings/UserSettings/form/optional/BankDetailsFormFields";
import { AddressFormFields } from "@/feature/settings/UserSettings/form/optional/AddressFormFields";
import { ZodObject } from "zod";
import { cn } from "@/lib/utils";
import { UserCheck } from "lucide-react";

interface EditCompanyFormProps {
  schema?: ZodObject<any>;
  initialValidationErrors?: Record<string, string>;
  onAbort?: () => void;
  onSave?: () => void;
}

export const EditCompanyForm: React.FC<EditCompanyFormProps> = ({
  schema = CompanyUpdateSchema,
  initialValidationErrors = {},
  onAbort,
  onSave,
}) => {
  const { t } = useTranslation();
  const [update, result] = companyApi.useUpdateCompanyMutation();
  const { activeCompany } = useLoggedIn();
  const { toast } = useToast();

  const companyToUpdate = {
    ...activeCompany,
    accounting: {
      ...activeCompany.accounting,
      taxSystem: activeCompany?.accounting?.taxSystem || "SKR03",
      invoiceNumberFormat:
        activeCompany?.accounting?.invoiceNumberFormat || "RE-{YY}{NNNN}",
    },
  } as CompanyUpdateData;

  const form = useForm<CompanyUpdateData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: companyToUpdate,
  });

  const [showLoading, setShowLoading] = React.useState(false);

  useEffect(() => {
    if (result.isLoading) {
      setShowLoading(true);
    } else {
      let timeout = setTimeout(() => {
        setShowLoading(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [result.isLoading]);

  useEffect(() => {
    Object.entries(initialValidationErrors).forEach(([field, message]) => {
      form.setError(field as any, { type: "manual", message });
    });
  }, [initialValidationErrors, form]);

  const handleAbort = () => {
    form.reset();
    if (onAbort) onAbort();
  };

  const handleSubmit = async (data: CompanyUpdateData) => {
    const result = await update(data);
    if ("error" in result) {
      console.error("company_err", result.error);
    } else {
      toast({
        icon: UserCheck,
        title: t("common.success"),
        description: t("component.companyForm.success"),
      });
      if (onSave) onSave();
    }
  };

  const hasCompanyInfoErrors = !!form.formState.errors.contactInfo;
  const hasAddressErrors = !!form.formState.errors.address;
  const hasCompanyContactInfoErrors = hasCompanyInfoErrors || hasAddressErrors;
  const hasAccountingErrors = !!form.formState.errors.accounting;
  const hasBankDetailsErrors = !!form.formState.errors.bankDetails;
  const accountingErrors = hasAccountingErrors || hasBankDetailsErrors;

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          form.handleSubmit(handleSubmit)(e);
        }}
        className={"flex flex-col gap-4"}
        autoComplete={"off"}
      >
        <HorizontalFlex>
          <VerticalFlex className={"flex-1"}>
            <GeneralInfoFormField form={form} />

            <Tabs className={"mt-4"} defaultValue={"companyContactInfo"}>
              <TabsList>
                <TabsTrigger
                  value={"companyContactInfo"}
                  className={cn(
                    hasCompanyContactInfoErrors &&
                      "relative border-dashed border-red-500",
                  )}
                >
                  {hasCompanyContactInfoErrors && (
                    <div
                      className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white"
                      title={t("common.formError")}
                    >
                      !
                    </div>
                  )}
                  {t("component.companyForm.companyContactInfo.title")}
                </TabsTrigger>
                <TabsTrigger
                  value={"accounting"}
                  className={cn(
                    accountingErrors && "relative border border-red-500",
                  )}
                >
                  {accountingErrors && (
                    <div
                      className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white"
                      title={t("common.formError")}
                    >
                      !
                    </div>
                  )}
                  {t("component.companyForm.accounting.title")}
                </TabsTrigger>
              </TabsList>

              <TabsContent value={"companyContactInfo"} className={"mt-2"}>
                <VerticalFlex
                  gap={8}
                  className={"rounded-lg border bg-card p-4"}
                >
                  <CompanyInfoFormFields form={form} />
                  <AddressFormFields form={form} />
                </VerticalFlex>
              </TabsContent>
              <TabsContent value={"accounting"} className={"mt-2"}>
                <VerticalFlex
                  gap={8}
                  className={"rounded-lg border bg-card p-4"}
                >
                  <BankDetailsFormFields form={form} />
                  <AccountingFormFields form={form} />
                </VerticalFlex>
              </TabsContent>
            </Tabs>
            <div className={"sticky bottom-0 grid grid-cols-4 gap-2"}>
              <Button
                type="button"
                className="col-start-3 hover:bg-red-900"
                variant={"ghost"}
                onClick={handleAbort}
              >
                {t("common.abort")}
              </Button>
              <Button
                type="submit"
                disabled={showLoading}
                className={"max-w-48"}
              >
                {showLoading && (
                  <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
                )}
                {t("component.contacts.submit")}
              </Button>
            </div>
          </VerticalFlex>
        </HorizontalFlex>
      </form>
    </Form>
  );
};
