import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Login } from "./feature/auth/Login";
import { Provider } from "react-redux";
import { store } from "./api/store";

import { ProtectedRoutes } from "./feature/auth/ProtectedRoutes";

import DefaultLayout from "@/components/page/DefaultLayout";
import Dashboard from "./feature/dashboard/Dashboard";
import DocumentsContent from "@/feature/documents/DocumentsContent";
import { SidebarItem } from "@/components/page/components/SideNavbar";
import {
  ArrowRightLeft,
  CircleUserRound,
  FileText,
  Home,
  Landmark,
  UserRoundCog,
  Sparkles,
  BookUser,
  MailOpen,
  Building2,
} from "lucide-react";
import TransactionsContent from "@/feature/transactions/TransactionsContent";
import MetricsAI from "./feature/metricsai/MetricsAiContent";
import UsersContents from "./feature/users/UsersContents";
import { Register } from "./feature/auth/Register";
import { AuthProvider } from "@/feature/auth/AuthProvider";

import { translation } from "@/i18n/translation";
import PageNotFound from "@/components/error/NotFound";
import ForgotPassword from "@/feature/auth/ForgotPassword";
import OnboardingOutlet from "@/components/page/OnboardingOutlet";
import ResetPassword from "@/feature/auth/ResetPassword";
import Complete from "@/feature/auth/Complete";
import { Invite } from "@/feature/auth/Invite";
import UserSettingsContent from "@/feature/settings/UserSettings/UserSettingsContent";
import BankSettingsContent from "@/feature/settings/BankSettings/BankSettingsContent";
import { GoCardlessSyncRequisition } from "@/feature/gocardless/GoCardlessSyncRequisition";
import { TooltipProvider } from "@/components/ui/tooltip";
import Contacts from "@/feature/contacts/Contacts";
import CompanySettingsContent from "@/feature/settings/CompanySettings/CompanySettingsContent";
import EditInvoice from "@/feature/invoices/edit/EditInvoice";
import Invoices from "@/feature/invoices/Invoices";
import FinalizeLoadingScreen from "@/feature/invoices/create/FinalizeLoadingScreen";
import CancelInvoice from "@/feature/invoices/edit/form/CancelInvoice";

const t = translation.t;

const sidebar: SidebarItem[] = [
  {
    title: t("component.app.sidebar.dashboard"),
    icon: <Home className="h-5 w-5" />,
    path: "/",
    position: "top",

    element: <Dashboard />,
  },
  {
    title: t("component.app.sidebar.metricsai"),
    icon: <Sparkles className="h-5 w-5" />,
    path: "/metricsai",
    position: "top",

    element: <MetricsAI />,
  },
  // {
  //   title: t("component.app.sidebar.expenses"),
  //   icon: <HandCoins className="h-5 w-5" />,
  //   path: "/expenses",
  //   position: "top",
  //   disabled: true,
  //   element: <ExpensesContent />,
  // },
  {
    title: t("component.app.sidebar.transactions"),
    icon: <ArrowRightLeft className="h-5 w-5" />,
    path: "/transactions",
    position: "top",

    element: <TransactionsContent />,
  },
  {
    title: t("component.app.sidebar.documents"),
    icon: <FileText className="h-5 w-5" />,
    path: "/documents",
    position: "top",

    element: <DocumentsContent />,
  },
  {
    title: t("component.app.sidebar.contacts"),
    icon: <BookUser className="h-5 w-5" />,
    path: "/contacts",
    position: "middle",
    element: <Contacts />,
  },
  {
    title: t("component.app.sidebar.invoice"),
    icon: <MailOpen className="h-5 w-5" />,
    path: "/invoices",
    position: "middle",
    element: <Invoices />,
  },
  {
    title: t("component.app.sidebar.settings.bank"),
    icon: <Landmark className="h-5 w-5" />,
    path: "/settings/bank",
    position: "bottom",
    element: <BankSettingsContent />,
  },
  {
    title: t("component.app.sidebar.users"),
    icon: <UserRoundCog className="h-5 w-5" />,
    path: "/users",
    position: "bottom",
    element: <UsersContents />,
  },
  {
    title: t("component.app.sidebar.settings.user"),
    icon: <CircleUserRound className="h-5 w-5" />,
    path: "/settings/user",
    position: "bottom",

    element: <UserSettingsContent />,
  },
  {
    title: t("component.app.sidebar.settings.company"),
    icon: <Building2 className="h-5 w-5" />,
    path: "/settings/company",
    position: "bottom",
    element: <CompanySettingsContent />,
  },
];

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },

  {
    path: "/onboarding",
    element: <OnboardingOutlet />,
    children: [
      {
        path: "forgot",
        element: <ForgotPassword />,
      },
      {
        path: "reset",
        element: <ResetPassword />,
      },
      {
        path: "complete",
        element: <Complete />,
      },
      {
        path: "invite",
        element: <Invite />,
      },
    ],
  },
  {
    element: <ProtectedRoutes />,
    path: "/",
    children: [
      {
        element: <DefaultLayout sidebar={sidebar} />,
        path: "/",
        children: sidebar
          .map((item) => ({
            element: item.element,
            path: item.path,
          }))
          .concat([
            {
              path: "*",
              element: <PageNotFound />,
            },
            {
              path: "gocardless/requisition/:id",
              element: <GoCardlessSyncRequisition />,
            },
            {
              path: "inv/:id",
              element: <EditInvoice />,
            },
            {
              path: "inv/:id/cancel",
              element: <CancelInvoice />,
            },
            {
              path: "inv/:id/finalize",
              element: <FinalizeLoadingScreen />,
            },
          ]),
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <TooltipProvider>
          <RouterProvider router={router}></RouterProvider>
        </TooltipProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
