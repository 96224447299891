import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "@/components/text/Title";
import { Chat } from "./components/chat/Chat";
import { GLOBAL_API_TOKEN } from "@/api/reduxApi";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { VerticalFlex } from "@/components/layout/Flex";

const MetricsAI: React.FC = () => {
  const { t } = useTranslation();
  const [initialMessages, setInitialMessages] = useState([]);
  const { user } = useLoggedIn();

  // Function to fetch messages
  const fetchMessages = async () => {
    try {
      const headers = new Headers();
      if (GLOBAL_API_TOKEN) {
        headers.append("Authorization", "Bearer " + GLOBAL_API_TOKEN);
      }

      const response = await fetch(
        process.env.REACT_APP_API_URL + "chatai/messages",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ id: "useCompanyIdInsteadOfUserId" }),
        },
      );

      if (!response.ok) {
        console.log("Error: Network response was not ok");
      }

      const data = await response.json();
      setInitialMessages(data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // useEffect to fetch messages on component load
  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <VerticalFlex className={"h-full"}>
      <Title>
        {user.firstname &&
          t("component.metricsai.main.welcomeMessage", {
            firstname: user.firstname,
          })}
      </Title>
      <Chat
        className="chat-container"
        id={1}
        initialMessages={initialMessages}
      />
    </VerticalFlex>
  );
};

export default MetricsAI;
