import React from "react";
import { Title } from "@/components/text/Title";
import { Text } from "@/components/text/Text";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Center } from "@/components/layout/Center";
import { Line } from "@/components/layout/Line";
import { useTranslation } from "react-i18next";
import { MailWarning } from "lucide-react";
import { CreateInvoiceComboButton } from "@/feature/invoices/create/CreateInvoiceComboButton";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <Center v h>
      <HorizontalFlex>
        <Title>404</Title>
        <Line v />
        <Text>{t("error.component.notFound")}</Text>
      </HorizontalFlex>
    </Center>
  );
};

export const InvoiceNotFound = () => {
  const { t } = useTranslation();

  return (
    <Center v h>
      <HorizontalFlex>
        <VerticalFlex align={"center"}>
          <MailWarning name="invoice-error" size={48} />
          <Title>{t("error.invoice.notFound")}</Title>
          <Text>404</Text>
          <CreateInvoiceComboButton />
        </VerticalFlex>
      </HorizontalFlex>
    </Center>
  );
};

export default PageNotFound;
