import React, { ReactNode, useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { Path } from "@/util/DeepProps";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";

export interface HasId {
  id: string;
}

export interface DataTableColumn<T extends HasId, SortKey = Path<T>> {
  id?: string;
  header?: ReactNode;
  cell: (data: T) => ReactNode;
  weight?: number;
  sortKey?: SortKey;
  className?: string;
}

export type Sort<T> = [T, "asc" | "desc"] | null;

interface DataTableProps<T extends HasId, SortKey> {
  columns: DataTableColumn<T, SortKey>[];
  onSortChange?: (key: Sort<SortKey>) => void;
  data: T[];
  selected?: string[];
  onClick?: (item: T) => void;
  rowBreak?: ((index: number) => boolean)[];
}

const DataTableHeader = <T extends HasId, SortKey>({
  column,
  sort,
  setSort,
  weight,
  className,
}: {
  column: DataTableColumn<T, SortKey>;
  sort: Sort<SortKey>;
  setSort: (sort: Sort<SortKey>) => void;
  weight?: number;
  className?: string;
}) => {
  let sorter: ReactNode = null;
  if (column.sortKey) {
    // selected
    if (sort && column.sortKey === sort[0]) {
      if (sort[1] === "asc") {
        sorter = <ArrowDown className="ml-2 h-4 w-4" />;
      } else {
        sorter = <ArrowUp className="ml-2 h-4 w-4" />;
      }
    } else {
      sorter = <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />;
    }
  }

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!column.sortKey) {
      return;
    }

    e.preventDefault();

    if (sort && column.sortKey === sort[0]) {
      setSort([sort[0], sort[1] === "asc" ? "desc" : "asc"]);
    } else {
      setSort([column.sortKey, "asc"]);
    }
  };

  return (
    <TableHead
      className={className ?? ""}
      style={
        weight
          ? {
              width: `${weight * 100}%`,
            }
          : {}
      }
    >
      <div
        className="flex cursor-pointer select-none flex-row hover:text-foreground"
        onClick={onClick}
      >
        {column.header}
        {sorter}
      </div>
    </TableHead>
  );
};

export const DataTable = <T extends HasId, SortKey = Path<T>>({
  rowBreak = [() => true],
  ...props
}: DataTableProps<T, SortKey>) => {
  const selected = props.selected || [];
  const [sort, setSort] = useState<Sort<SortKey>>(null);
  const [hoverIndex, setHoverIndex] = useState<number>();

  useEffect(() => {
    props.onSortChange?.(sort);
  }, [sort, props]);

  const { t } = useTranslation();

  let totalWeight = props.columns.reduce(
    (acc, column) => acc + (column.weight || 1),
    0,
  );

  return (
    <Table>
      <TableHeader className="text-xs uppercase">
        {rowBreak.map((fn, i) => (
          <TableRow key={i}>
            {props.columns
              .filter((_, i) => fn(i))
              .map((column, index) => (
                <DataTableHeader
                  className={column.className}
                  column={column}
                  sort={sort}
                  setSort={setSort}
                  weight={
                    column.weight ? column.weight / totalWeight : undefined
                  }
                  key={column.id || index}
                />
              ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {props.data.map((row, rowIndex) =>
          rowBreak.map((fn, i) => (
            <TableRow
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(undefined)}
              className={cn(
                "relative h-16",
                (selected.includes(row.id) || rowIndex === hoverIndex) &&
                  "group/edit bg-muted/50",
              )}
              key={row.id + "i"}
              onClick={() => {
                props.onClick?.(row);
              }}
            >
              {props.columns
                .filter((_, i) => fn(i))
                .map((column, index) => (
                  <TableCell
                    key={index + "-" + row.id}
                    className={column.className}
                  >
                    {column.cell(row)}
                  </TableCell>
                ))}
            </TableRow>
          )),
        )}
        {props.data.length === 0 && (
          <TableRow>
            <TableCell
              colSpan={props.columns.length}
              className="h-24 text-center"
            >
              {t("common.noDataResults")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
