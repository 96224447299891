import React, { useState } from "react";
import {
  DataTable,
  DataTableColumn,
  Sort,
} from "@/components/display/DataTable";
import { ContactType, ContactWithId } from "@/models/contact";
import { DataTableMultiselect } from "@/components/display/DataTableMultiselect";
import Spinner from "@/components/loading/spinner";
import { useFilterContactsQuery } from "@/api/endpoints/contactsApi";
import { Filter, FilterInner, FilterParameters } from "@/api/types";
import { t } from "i18next";
import { VerticalFlex } from "@/components/layout/Flex";
import { Path } from "@/util/DeepProps";
import { ContactTypeComponent } from "@/feature/contacts/Contacts";
import ContactActions from "@/feature/contacts/tables/ContactActions";
import { DataTablePagination } from "@/components/display/DataTablePagination";

const ContactTable = ({ type }: { type?: ContactType }) => {
  const [filter, setFilter] = useState<FilterInner<ContactWithId>>({});
  const [sort, setSort] = useState<Sort<Path<ContactWithId>>>(null);

  const [filterParams, setFilterParams] =
    useState<FilterParameters<ContactWithId>>();

  let filterQuery: Filter<ContactWithId> = {
    sort: sort ? sort[0] : ("customerNumber" as any),
    direction: sort ? sort[1] : "asc",
    ...(type
      ? {
          kind: "and",
          children: [
            {
              kind: "eq",
              attribute: "type",
              value: type,
            },
          ],
        }
      : {}),
    ...filter,
    ...filterParams,
  };
  const { data, isLoading } = useFilterContactsQuery(filterQuery);

  const contactColumns: DataTableColumn<ContactWithId>[] = [
    {
      header: t("model.contact.type"),
      sortKey: "type",
      cell: (item) => (
        <ContactTypeComponent type={item.type ?? ContactType.Supplier} />
      ),
    },
    {
      header: t("model.contact.customerNumber"),
      sortKey: "customerNumber",
      cell: (item) => {
        return <>{item.customerNumber ?? "N/A"}</>;
      },
    },
    {
      header: t("model.contact.companyName"),
      sortKey: "companyName",
      cell: (item) => item.companyName,
    },
    {
      header: t("model.contact.mail"),
      cell: (item) => {
        return (
          <a href={"mailto:" + item.contactInfo?.email}>
            {item.contactInfo?.email}
          </a>
        );
      },
    },
    {
      header: t("component.contacts.phone"),
      cell: (item) => {
        return (
          <a href={"tel:" + item.contactInfo?.phone}>
            {item.contactInfo?.phone}
          </a>
        );
      },
    },
    {
      header: <></>,
      cell: (item) => <ContactActions item={item} />,
    },
  ];

  return (
    <VerticalFlex gap={2}>
      <DataTableMultiselect<ContactWithId>
        textSearchKeys={["companyName"]}
        selectGroups={[]}
        placeholder={t("component.contacts.table.search")}
        onChange={setFilter}
      ></DataTableMultiselect>
      {isLoading && <Spinner />}
      {!isLoading && (
        <DataTable<ContactWithId>
          onSortChange={setSort}
          columns={contactColumns}
          data={data?.items ?? []}
        />
      )}
      <DataTablePagination
        onChange={setFilterParams}
        defaultPageSize={25}
        result={data}
      />
    </VerticalFlex>
  );
};

export default ContactTable;
