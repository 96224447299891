import { z } from "zod";
import { date } from "@/models/common";
import { Option } from "@/components/ui/multiple-selector";

export const unitCodes: Option[] = [
  { label: "Stk", description: "Stück", value: "C62" },
  { label: "h", description: "Stunde", value: "HUR" },
  { label: "kg", description: "Kilogramm", value: "KGM" },
  { label: "t", description: "Tonne", value: "TNE" },
  { label: "m", description: "Meter", value: "MTR" },
  { label: "m²", description: "Quadratmeter", value: "MTK" },
  { label: "m³", description: "Kubikmeter", value: "MTQ" },
  { label: "L", description: "Liter", value: "LTR" },
  { label: "d", description: "Tag", value: "DAY" },
  { label: "mo", description: "Monat", value: "MON" },
  { label: "-", description: "Keine Einheit", value: "" },
];

const unitCodesValues = [...unitCodes.map((unit) => unit.value)] as const;

const unitCodesTuple = unitCodesValues as [string, ...string[]];

export const unitCodesEnum = z.enum(unitCodesTuple);

const addressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  line3: z.string().optional(),
  city: z.string(),
  postcode: z.string(),
  countryId: z
    .string()
    .min(1, { message: "Country ID is required" })
    .default("DE"),
  countrySubdivisionName: z.string().optional(),
});

const buyerInfoSchema = z.object({
  name: z.string(),
  legalId: z.string().optional(),
  address: addressSchema,
  email: z.string().email().optional(),
  customerNumber: z.number().optional(),
});

export const itemSchema = z.object({
  name: z.string(),
  quantity: z.string().refine(
    (val) => {
      const numberValue = parseFloat(val);
      return !isNaN(numberValue) && numberValue > 0;
    },
    {
      message: "Muss eine positive Zahl sein",
    },
  ),
  description: z.string().optional(),
  unit: z.string().optional(),
  unitPrice: z.number().positive(),
  taxRate: z.string().refine(
    (val) => {
      const numberValue = parseFloat(val);
      return !isNaN(numberValue) && numberValue >= 0 && numberValue <= 10000;
    },
    {
      message: "Der Steuersatz muss eine Zahl zwischen 0 und 10000 sein",
    },
  ),
  discount: z
    .preprocess(
      (val) =>
        typeof val === "string" || typeof val === "number"
          ? parseFloat(val.toString())
          : NaN,
      z.number().min(0).max(10000),
    )
    .transform((val) => val.toString()),
  position: z.string(),
});

export const invoiceRequestSchema = z.object({
  issueDate: date,
  dueDate: date.optional(),
  performancePeriodFrom: date,
  performancePeriodTo: date.nullable().optional(),
  note: z.string().optional(),
  reference: z.string().optional(),
  referenceIssueDate: date.optional(),
  buyer: buyerInfoSchema,
  priceType: z.enum(["gross", "net"], {
    message: "Price type must be 'gross' or 'net'",
  }),
  items: z
    .array(itemSchema)
    .min(1, { message: "At least one line item is required" }),
  additional: z.string().optional(),
  taxRateReason: z.string(),
});

export type InvoiceRequest = z.infer<typeof invoiceRequestSchema>;

export const createInvoiceRequest = z.object({
  typeCode: z.string(),
  originalInvoiceId: z.string().optional(), // for cancelling invoices
});
export type CreateInvoiceRequest = z.infer<typeof createInvoiceRequest>;

type BT3Code = {
  code: string;
  english_codename: string;
  german_codename: string;
};

export type BT3Codes = BT3Code[];

export type InvoiceDraft = {
  companyId: string;
  createdAt: string;
  creatorUserCompanyId: string;
  deletedAt: string;
  documentId: string;
  draftRequest: string | InvoiceRequest;
  id: string;
  invoiceNumber: string;
  status: InvoiceStatus;
  typeCode: string;
  updatedAt: string;
  xml: string;
};

export type InvoiceStatus =
  | "DRAFT"
  | "PENDING"
  | "SENT"
  | "PAID"
  | "FAILED"
  | "CANCELLED";
