export const INVOICE_BT3_CODES: {
  code: string;
  description: string;
  germanDescription: string;
}[] = [
  {
    code: "380",
    description: "Commercial invoice",
    germanDescription: "Handelsrechnung",
  },
  // {
  //   code: "325",
  //   description: "Proforma invoice",
  //   germanDescription: "Proformarechnung",
  // },
  {
    code: "381",
    description: "Credit note notification",
    germanDescription: "Gutschriftanzeige",
  },
  {
    code: "387",
    description: "Rental invoice",
    germanDescription: "Mietrechnung",
  },
  {
    code: "388",
    description: "Tax invoice",
    germanDescription: "Steuerrechnung",
  },
  // {
  //   code: "389",
  //   description: "Self-billed invoice",
  //   germanDescription:
  //     "Selbst ausgestellte Rechnung (Gutschrift im Gutschriftsverfahren)",
  // },
];
